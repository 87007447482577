import { W_0 } from "./acoustic-constants";
import { DecayFunction } from "./decay-time";
import {
  DIMENSION,
  RoomSingleFreq,
  SixDirectional,
  TriDimensional
} from "./room";

/**
 * Total area weighted average absorption factor of the room.
 * ̅α or αₛ from (Zhou et al., 2021, Eq. 4)
 * @param room Room
 */
export function weightedAverageAlpha(room: RoomSingleFreq): number {
  const x = room[DIMENSION.x];
  const y = room[DIMENSION.y];
  const z = room[DIMENSION.z];
  return (
    (x.alpha * y.L * z.L + y.alpha * z.L * x.L + z.alpha * x.L * y.L) /
    (x.L * y.L + y.L * z.L + z.L * x.L)
  );
}

/**
 * Returns the total area of each wall in m^2.
 * @param roomSize 3D-room dimensions
 */
export function wallsTotalArea(
  roomSize: TriDimensional<number>
): SixDirectional<number> {
  const wallArea = Array(6) as SixDirectional<number>;
  wallArea[1] = wallArea[0] = chopDecimals(roomSize[1] * roomSize[2], 2);
  wallArea[3] = wallArea[2] = chopDecimals(roomSize[0] * roomSize[2], 2);
  wallArea[5] = wallArea[4] = chopDecimals(roomSize[0] * roomSize[1], 2);
  return wallArea;
}
function chopDecimals(value: number, decimals: number): number {
  return parseFloat(value.toFixed(decimals));
}

/**
 * The total surface area of the room in m^2.
 * S from (Zhou et al., 2021, Eq. 4)
 * @param Lx room length in x direction
 * @param Ly room length in y direction
 * @param Lz room length in z direction
 */
export function totalSurfaceArea(Lx: number, Ly: number, Lz: number): number {
  return 2 * (Lx * Ly + Ly * Lz + Lz * Lx);
}

/**
 * A_e the Eyring total equivalent sound absorption area of the room in m^2.
 * A_e is calculated by the expression of Eyring.
 * A_e from (Zhou et al., 2021, Eq. 4)
 * @param alpha_s area weighted average absorption factor of the room
 * @param S Total surface area of the room
 */
export function equivalentAbsorptionArea_Eyring(alpha_s: number, S: number) {
  return -Math.log(1 - alpha_s) * S;
}

/**
 * A the Sabine total equivalent sound absorption area of the room in m^2.
 * A is calculated by ̅α * S.
 * A from (DIN 18041:2016-03, 4.3.3/3.14 and DIN 1320:2009-12, 12.5)
 * @param alpha_s area weighted average absorption factor of the room
 * @param S Total surface area of the room
 */
export function equivalentAbsorptionArea_Sabine(alpha_s: number, S: number) {
  return alpha_s * S;
}
/**
 * A/V: Ratio between the equivalent sound absorption area and the room volume.
 * (DIN 18041:2016-03, 4.3.3)
 * @param A A = α * S the Sabine total equivalent sound absorption area of the room in m^2 (DIN 18041:2016-03, 4.3.3/3.14 and DIN 1320:2009-12, 12.5)
 * @param S Total surface area of the room
 */
export function absorptionAreaVolumeRatio(A: number, V: number): number {
  return A / V;
}

/**
 * Free path length of the 3D sound field in the room, in m
 * From (Zhou et al., 2021, Eq. 7)
 */
export function freePathLength3D(
  Lx: number,
  Ly: number,
  Lz: number,
  S: number
) {
  return (4 * Lx * Ly * Lz) / S;
}

/**
 * Squared sound pressure of the diffuse field at t.
 * p_s^2(t) from (Zhou et al., 2021, Eq. 17)
 * This equals the Eyring formula of calculating reverberation.
 */
export function diffuseSquaredSoundPressure(
  P2_s0: number,
  c_0: number,
  L_s: number,
  m_f: number,
  alpha_s: number
): DecayFunction {
  const expFactor = (-c_0 / L_s) * (m_f * L_s - Math.log(1 - alpha_s));
  return time => P2_s0 * Math.exp(time * expFactor);
}

/**
 * Squared sound pressure at t using C.Sabine method.
 */
export function sabineSquaredSoundPressure(
  P2_s0: number,
  c_0: number,
  L_s: number,
  m_f: number,
  alpha_s: number
): DecayFunction {
  const expFactor = (-c_0 / L_s) * (m_f * L_s + alpha_s);
  return time => P2_s0 * Math.exp(time * expFactor);
}

/**
 * Initial squared sound pressure of the diffuse sound field component.
 *
 * Equals p^2_{s,1} from (Zhou et al., 2021, Eq. 6)
 *
 * Equals p^2_{s0} from (Zhou et al., 2021, Eq. 17)
 * @param room Room
 * @param direction Direction i
 */
export function initialDiffuseSquaredSoundPressure(
  p2_x_1: number,
  p2_y_1: number,
  p2_z_1: number,
  m_f: number,
  rho_0: number,
  c_0: number,
  L_s: number,
  alpha_s: number,
  A_e: number,
  V: number
): number {
  /** From (Zhou et al., 2021, Eq. 5) */
  const p2_s_total = (4 * rho_0 * c_0 * W_0) / (A_e + 4 * m_f * V);
  // Todo: Is this an error in (Zhou et al., 2021, Eq. 5): Where does "+ 4 * m_f * V" come from

  /**
   * Total remaining squared sound pressure level of the
   * diffuse sound field after one reflexion on the room
   * surfaces, in Pa²
   * From (Zhou et al., 2021, Eq. 6)
   */
  const p2_s_total1 = p2_s_total * Math.exp(-m_f * L_s + Math.log(1 - alpha_s));

  /** From (Zhou et al., 2021, Eq. 6) */
  const p2_s_1 = p2_s_total1 - p2_x_1 - p2_y_1 - p2_z_1;
  return p2_s_1;
}
