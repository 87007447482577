import { DetailedResult } from "@/calc/sound-pressure-summed";
import { store } from "../../state/state";

export async function setupAudioComponent(): Promise<void> {
  return new Promise(resolve => {
    const data: DetailedResult = store.result!;
    const zhouTimes = data.octoFrequential.map(
      item => item.reverberationTimeZhou
    );
    zhouTimes.shift();
    zhouTimes.pop();
    const sumOfZhouTimes = zhouTimes.reduce((sum, time) => sum + time, 0);
    const averageDuration = sumOfZhouTimes / zhouTimes.length;
    let lowPassFrequency = 5620;
    let BP_Q = 3;

    let source: AudioBufferSourceNode;
    let audioBuffer: AudioBuffer;
    let dryGain: GainNode;
    let wetGain: GainNode;
    let BP_Filter: BiquadFilterNode;
    let lowpassFilter: BiquadFilterNode;
    let dry: number;
    let wet: number;

    const reverbs: { [key: number]: ConvolverNode } = {};
    const audioFile = require("@/assets/wav/davide-voice.wav");

    let audioContext: AudioContext = new AudioContext();

    fetch(audioFile)
      .then(response => response.arrayBuffer())
      .then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer))
      .then(decodedBuffer => {
        audioBuffer = decodedBuffer;
        if (audioBuffer) {
          startAudio();
        }
      });

    function generateImpulseResponse(durationInSeconds: number): AudioBuffer {
      const impulseLength = Math.ceil(
        audioContext.sampleRate * durationInSeconds
      );
      const impulseBuffer = audioContext.createBuffer(
        2,
        impulseLength,
        audioContext.sampleRate
      );

      let maxAmplitude = 0; // Initialize max amplitude variable
      for (
        let channel = 0;
        channel < impulseBuffer.numberOfChannels;
        channel++
      ) {
        const data = impulseBuffer.getChannelData(channel);
        for (let i = 0; i < impulseLength; i++) {
          // Calculate the decay factor (exponential decay)
          const decayFactor = Math.exp(-6 * (i / impulseLength));

          // Generate values with the envelope and decreasing amplitude
          data[i] = decayFactor * (Math.random() * 2 - 1);

          // Update max amplitude
          maxAmplitude = Math.max(maxAmplitude, Math.abs(data[i]));
        }
      }

      // Normalize the impulse response to -1 to 1
      const scalingFactor = 1 / maxAmplitude;
      for (
        let channel = 0;
        channel < impulseBuffer.numberOfChannels;
        channel++
      ) {
        const data = impulseBuffer.getChannelData(channel);
        for (let i = 0; i < impulseLength; i++) {
          data[i] *= scalingFactor;
        }
      }
      return impulseBuffer;
    }
    function getLongestReverbDuration(): number {
      return Math.max(...zhouTimes);
    }

    const longestReverbDuration = getLongestReverbDuration();
    generateImpulseResponse(longestReverbDuration);

    function createReverbs(
      aCTX: BaseAudioContext,
      reverbTimesInSeconds: number[]
    ): void {
      reverbTimesInSeconds.forEach((duration, i) => {
        if (duration <= 0) duration = 0.01;
        const key = (125 * Math.pow(2, i)) as number;
        reverbs[key] = aCTX.createConvolver();
        reverbs[key].buffer = generateImpulseResponse(duration);
      });
    }

    // biquadfilternode function taking param: frequency, type, Q
    function createBiquadFilterNode(
      frequency: number,
      aCTX: BaseAudioContext
    ): BiquadFilterNode {
      const filter = aCTX.createBiquadFilter();
      filter.type = "bandpass";
      filter.frequency.value = frequency;
      filter.Q.value = BP_Q;
      return filter;
    }

    function createLowPassFilterNode(aCTX: BaseAudioContext): BiquadFilterNode {
      const lowpassFilter = aCTX.createBiquadFilter();
      lowpassFilter.type = "lowpass";
      lowpassFilter.Q.value = 1;
      lowpassFilter.frequency.value = lowPassFrequency;
      return lowpassFilter;
    }

    function setupAudioChain(): void {
      if (!audioBuffer) return;

      source = audioContext.createBufferSource();
      source.buffer = audioBuffer;
      source.channelCount = audioBuffer.numberOfChannels;

      // Adjust the audioBuffer length to match the offlineContext
      const longestReverb = Math.max(...zhouTimes);
      const offlineContextLength =
        audioBuffer.length + audioBuffer.sampleRate * longestReverb;
      if (audioBuffer.length !== offlineContextLength) {
        // Create a new AudioBuffer with the correct length
        const newAudioBuffer = audioContext.createBuffer(
          audioBuffer.numberOfChannels,
          offlineContextLength,
          audioBuffer.sampleRate
        );
        // Copy the original data into the new buffer
        for (
          let channel = 0;
          channel < audioBuffer.numberOfChannels;
          channel++
        ) {
          newAudioBuffer
            .getChannelData(channel)
            .set(audioBuffer.getChannelData(channel), 0);
        }
        audioBuffer = newAudioBuffer;
      }

      source = audioContext.createBufferSource();
      source.buffer = audioBuffer;

      dryGain = audioContext.createGain();
      wetGain = audioContext.createGain();

      calcDryWet(2);
      dryGain.gain.value = dry || 0;
      wetGain.gain.value = wet || 0;

      if (!source || !dryGain || !wetGain) return;

      source.connect(dryGain);

      Object.keys(reverbs).forEach(key => {
        const numericKey = parseInt(key);
        if (!source || isNaN(numericKey) || !reverbs[numericKey]) return;
        dryGain.connect(reverbs[numericKey]);
        reverbs[numericKey].connect(wetGain);
      });

      for (let [key, value] of Object.entries(reverbs)) {
        const numericKey = parseInt(key);
        if (isNaN(numericKey) || !value) continue;
        const BP_Filter = createBiquadFilterNode(numericKey, audioContext);
        wetGain.connect(BP_Filter);
        BP_Filter.connect(lowpassFilter);
      }

      if (lowpassFilter) {
        wetGain.connect(lowpassFilter);
        lowpassFilter.connect(audioContext.destination);
      }
      dryGain.connect(audioContext.destination);
    }

    function getReverbDurations(aCTX: BaseAudioContext): void {
      const reverbDurationsInSeconds = zhouTimes;
      createReverbs(aCTX, reverbDurationsInSeconds);
    }

    function startAudio(): void {
      if (!audioBuffer) return;

      audioContext = new AudioContext({
        sampleRate: audioBuffer.sampleRate,
        latencyHint: "interactive"
      });

      source = audioContext.createBufferSource();
      source.buffer = audioBuffer;
      source.channelCount = audioBuffer.numberOfChannels;

      lowpassFilter = createLowPassFilterNode(audioContext);
      getReverbDurations(audioContext);

      if (source) {
        disconnectAudioContext();
      }

      setupAudioChain();
      source.onended = stopAudio;
    }

    function stopAudio(): void {
      if (!source) return;
      source.stop();
    }

    function disconnectAudioContext(): void {
      if (dryGain) {
        dryGain.disconnect(audioContext.destination);
      }
      if (wetGain) {
        wetGain.disconnect(audioContext.destination);
      }
      for (let value of Object.values(reverbs)) {
        if (value) {
          value.disconnect();
        }
      }
      source.connect(audioContext.destination);
      if (audioContext.state === "running") {
        audioContext.suspend().then(() => {
          audioContext.resume();
        });
      }
    }

    function calcDryWet(r: number): void {
      const T = averageDuration;
      const V = data.V;

      // Revised Xiaoru's formula:
      const E_direkt = 1 / (4 * Math.PI * Math.pow(r, 2));
      const E_diffus = (4 * T) / (0.161 * V);
      const totalEnergy = E_direkt + E_diffus;
      dry = E_direkt / totalEnergy;
      wet = E_diffus / totalEnergy;

      dry = Math.max(0, Math.min(1, dry)); // Clamp dry value between 0 and 1
      wet = Math.max(0, Math.min(1, wet)); // Clamp wet value between 0 and 1
    }

    function renderWAV(): void {
      if (!audioBuffer || !dry || !wet || !reverbs) {
        return;
      }

      const offlineContextLength = audioBuffer.length;
      const offlineContext = new OfflineAudioContext(
        2,
        offlineContextLength,
        audioBuffer.sampleRate
      );

      const source = offlineContext.createBufferSource();
      source.buffer = audioBuffer;

      dryGain = offlineContext.createGain();
      wetGain = offlineContext.createGain();

      dryGain.gain.value = dry;
      wetGain.gain.value = wet;

      source.connect(dryGain);

      getReverbDurations(offlineContext);

      // Create BP_Filter and lowpassFilter within the same offline context
      BP_Filter = createBiquadFilterNode(125, offlineContext);
      lowpassFilter = createLowPassFilterNode(offlineContext);

      for (let [key, value] of Object.entries(reverbs)) {
        const numericKey = parseInt(key);
        if (isNaN(numericKey) || !value) continue;
        source.connect(value);
        value.connect(BP_Filter);
        BP_Filter.connect(lowpassFilter);
        lowpassFilter.connect(wetGain);
      }

      dryGain.connect(offlineContext.destination);
      wetGain.connect(offlineContext.destination);
      source.start(0);

      offlineContext.startRendering().then(renderedBuffer => {
        downloadWAV(renderedBuffer);
      });
    }

    function downloadWAV(audioBuffer: AudioBuffer): void {
      const wavData = audioBufferToWav(audioBuffer);
      const blob = new Blob([wavData], { type: "audio/wav" });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = "reverberate_auralization.wav";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      resolve();
    }

    function audioBufferToWav(buffer: AudioBuffer): Blob {
      const leftChannel = buffer.getChannelData(0);
      const rightChannel =
        buffer.numberOfChannels > 1
          ? buffer.getChannelData(1)
          : buffer.getChannelData(0);
      const interleaved = interleave(leftChannel, rightChannel);

      const dataview = encodeWAV(interleaved);
      const audioBlob = new Blob([dataview], { type: "audio/wav" });

      return audioBlob;
    }

    function encodeWAV(samples: any) {
      const buffer = new ArrayBuffer(44 + samples.length * 2);
      const view = new DataView(buffer);

      /* RIFF identifier */
      writeString(view, 0, "RIFF");
      /* RIFF chunk length */
      view.setUint32(4, 36 + samples.length * 2, true);
      /* RIFF type */
      writeString(view, 8, "WAVE");
      /* format chunk identifier */
      writeString(view, 12, "fmt ");
      /* format chunk length */
      view.setUint32(16, 16, true);
      /* sample format (1 for PCM) */
      view.setUint16(20, 1, true);
      /* channel count */
      view.setUint16(22, 2, true);
      /* sample rate */
      view.setUint32(24, audioContext.sampleRate, true);
      /* byte rate (sample rate * block align) */
      view.setUint32(28, audioContext.sampleRate * 4, true);
      /* block align (channel count * bytes per sample) */
      view.setUint16(32, 4, true);
      /* bits per sample */
      view.setUint16(34, 16, true);
      /* data chunk identifier */
      writeString(view, 36, "data");
      /* data chunk length */
      view.setUint32(40, samples.length * 2, true);

      floatTo16BitPCM(view, 44, samples);

      return view;
    }

    function writeString(view: DataView, offset: number, string: string) {
      for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
      }
    }

    function interleave(leftChannel: any, rightChannel: any) {
      const length = leftChannel.length;
      const result = new Float32Array(length * 2);

      for (let inputIndex = 0, outputIndex = 0; inputIndex < length; ) {
        result[outputIndex++] = leftChannel[inputIndex];
        result[outputIndex++] = rightChannel[inputIndex];
        inputIndex++;
      }

      return result;
    }

    function floatTo16BitPCM(
      output: DataView,
      offset: number,
      input: string | any[]
    ) {
      for (let i = 0; i < input.length; i++, offset += 2) {
        const s = Math.max(-1, Math.min(1, input[i]));
        output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
      }
    }

    setTimeout(() => {
      renderWAV();
    }, 5000);
  });
}
