import { Atmosphere } from "@/calc/acoustic-constants";
import { DIN_SCENARIOS } from "@/calc/din-requirements";
import { SixRoomWalls, TriDimensional } from "@/calc/room";
import { CalculationState, FurnitureConfiguration } from "@/state/state";

/** Creates a complete Bookmark-Link including the bookmark code */
export function createBookmarkLink(calc: CalculationState): string {
  return (
    window.location.origin +
    window.location.pathname +
    "#/calc/" +
    encodeBookmark(calc)
  );
}

export function encodeBookmark(calc: CalculationState): string {
  /* Version B includes Furniture & People Data. 
  If there is none, a empty furniture arr will be created. */
  const version = "B";
  const code = encodeBase64URL(compressSettings(calc));
  const hash = tinyChecksum(code);
  return version + hash + code;
}

/** Decodes calculation parameters from bookmark code. */
export function decodeBookmark(bookmark: string): CalculationState {
  if (bookmark.length <= 5) {
    throw new Error("Bookmark link to short.");
  }
  const version = bookmark[0];
  if (version !== "A" && version !== "B") {
    throw new Error("Bookmark encoding unsupported by this App version.");
  }
  const hash = bookmark.substr(1, 4);
  const code = bookmark.substring(5);
  if (tinyChecksum(code) !== hash) {
    throw new Error("Checksum failed. Bookmark link incomplete or typo.");
  }
  if (version === "B") {
    return decompressSettings(decodeBase64URL(code), "B");
  } else {
    return decompressSettings(decodeBase64URL(code), "A");
  }
}

/**
 * convert a Unicode string to a string in which
 * each 16-bit unit occupies only one byte
 * (Source: https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/btoa)
 *
 * */
export function packUTF16IntoUTF8(string: string) {
  const codeUnits = new Uint16Array(string.length);
  for (let i = 0; i < codeUnits.length; i++) {
    codeUnits[i] = string.charCodeAt(i);
  }
  return String.fromCharCode(...new Uint8Array(codeUnits.buffer));
}

/**
 * Reverses the conversion of packUTF16IntoUTF8()
 */
export function unpackUTF16FromUTF8(binary: string): string {
  const bytes = new Uint8Array(binary.length);
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }
  return String.fromCharCode(...new Uint16Array(bytes.buffer));
}

/** Replaces critical characters for URL compatible string */
export function encodeBase64URL(settingsObj: any) {
  const substitution: { [key: string]: string } = {
    "+": "-",
    "/": "_",
    "=": ""
  };
  return btoa(JSON.stringify(settingsObj)).replace(
    /[+/=]/g,
    match => substitution[match]
  );
}
export function decodeBase64URL(base64String: string): any {
  const substitution: { [key: string]: string } = {
    "-": "+",
    _: "/"
  };
  return JSON.parse(
    atob(base64String.replace(/[-_]/g, match => substitution[match]))
  );
}

/** Returns a new material-dictionary, that can be used to index material ids that are used in the url */
export function makeDict(): MaterialDictionary {
  const _list: string[] = [];
  const _reverse: { [key: string]: string } = {};
  return {
    index(m: string) {
      if (Object.prototype.hasOwnProperty.call(_reverse, m)) {
        return _reverse[m];
      } else {
        const index = `${_list.length}`;
        _list.push(m);
        _reverse[m] = index;
        return index;
      }
    },
    getList() {
      return Array.from(_list);
    }
  };
}

/* FURNITURE & PEOPLE Compress Object to String */
/* inspired by compressRoomwalls, we do not stringify but use join to seperate with a seperator */
export function compressFurniture(
  furnitureConfig: FurnitureConfiguration[]
): string {
  return furnitureConfig
    .map(
      furniture =>
        `${furniture.uuid},${furniture.quantity},${furniture.position}`
    )
    .join("|");
}

/* FURNITURE & PEOPLE: Decompress String to Object to load it up in a bookmark*/
export function decompressFurniture(
  compressedFurniture: string
): FurnitureConfiguration[] {
  return compressedFurniture.split("|").map(str => {
    const [idStr, quantityStr, positionStr] = str.split(",");
    const uuid = idStr;
    const quantity = Number(quantityStr);
    const position = Number(positionStr);
    return {
      uuid: uuid,
      quantity: quantity,
      position: position
    };
  });
}

/** Returns the compressed roomWalls while adding their materials to the given dictionaries. */
export function compressRoomWalls(
  matAlphaDict: MaterialDictionary,
  matScDict: MaterialDictionary,
  roomWalls: SixRoomWalls
): compressedRoomWalls {
  return roomWalls
    .map(w =>
      w
        .map(
          a =>
            `${a.area},${matAlphaDict.index(a.mat)},${matScDict.index(a.matSc)}`
        )
        .join(",")
    )
    .join("|");
}

export function decompressRoomWalls(
  matAlpha: compressedMaterials,
  matSc: compressedMaterials,
  roomWalls: compressedRoomWalls
): SixRoomWalls {
  const sixWalls = roomWalls.split("|").map(w => {
    const ungrouped = w.split(",");
    const wall = [];
    if (ungrouped.length % 3 !== 0 || ungrouped.length === 0) {
      throw Error("Material Assignments corrupted");
    }
    for (let i = 0; i < ungrouped.length - 2; i += 3) {
      wall.push({
        area: ungrouped[i] === "null" ? null : Number.parseFloat(ungrouped[i]),
        mat: matAlpha[Number.parseFloat(ungrouped[i + 1])],
        matSc: matSc[Number.parseFloat(ungrouped[i + 2])]
      });
    }
    return wall;
  });

  if (sixWalls.length !== 6) {
    throw Error("corrupted URL: wall count is not 6");
  }
  return sixWalls as SixRoomWalls;
}

export interface MaterialDictionary {
  /**
   * Adds a material to the dictionary and returns the index.
   * If the material already exists just returns the index of the existing.
   * */
  index(m: string): string | number;
  /** Returns an array copy of the dictionary  */
  getList(): string[];
}

export type compressedMaterials = string[];
export type compressedRoomWalls = string;
export type compressedFurniture = string;

export function compressSettings(
  calculation: CalculationState
): SettingsPortable {
  const matAlphaDict = makeDict();
  const matScDict = makeDict();
  return [
    calculation.roomSize[0],
    calculation.roomSize[1],
    calculation.roomSize[2],
    calculation.atmosphere.T,
    calculation.atmosphere.hum_rel,
    calculation.atmosphere.pa,
    calculation.dinScenario,
    compressRoomWalls(matAlphaDict, matScDict, calculation.roomWalls),
    matAlphaDict.getList(),
    matScDict.getList(),
    //Furniture
    compressFurniture(calculation.furniture)
  ];
}

export type SettingsPortable = [
  number,
  number,
  number,
  Atmosphere["T"],
  Atmosphere["hum_rel"],
  Atmosphere["pa"],
  DIN_SCENARIOS,
  compressedRoomWalls,
  compressedMaterials,
  compressedMaterials,
  compressedFurniture // Furniture Daten hinzugefügt
];

export function decompressSettings(
  compressedSettings: SettingsPortable,
  version: "A" | "B"
): CalculationState {
  if (compressedSettings.length < 10) {
    console.log("ERRR", compressedSettings.length);
    throw Error(
      "Compressed settings array has the wrong length. Excpected 10, but got " +
        compressedSettings.length +
        " elements."
    );
  }
  const roomSize: TriDimensional<number> = [
    compressedSettings[0],
    compressedSettings[1],
    compressedSettings[2]
  ];
  const atmosphere: Atmosphere = {
    T: compressedSettings[3],
    hum_rel: compressedSettings[4],
    pa: compressedSettings[5]
  };
  const dinScenario: DIN_SCENARIOS = compressedSettings[6];
  if (!Object.values(DIN_SCENARIOS).includes(dinScenario)) {
    throw new Error(`Unknown value for DIN scenario.`);
  }
  const roomWalls: SixRoomWalls = decompressRoomWalls(
    compressedSettings[8],
    compressedSettings[9],
    compressedSettings[7]
  );

  // Add empty Arr for Backwards Compatibility when uploading Version A Bookmark
  let furniture: FurnitureConfiguration[];
  // Detect Version B with Furniture & People. Should never be more than 11 elements.
  // Index 10 is Furniture & People
  if (version === "B" && compressedSettings[10]) {
    furniture = decompressFurniture(compressedSettings[10]);
  } else {
    furniture = [];
  }
  return { atmosphere, roomSize, dinScenario, roomWalls, furniture };
}

/** inspired by https://stackoverflow.com/a/7616484 */
export function tinyChecksum(code: string): string {
  let hash = 0,
    i,
    chr;
  if (code.length !== 0) {
    for (i = 0; i < code.length; i++) {
      chr = code.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
  }
  return hash
    .toString(32)
    .slice(-4) // only use the last 4 chars
    .padEnd(4, "U"); // make sure the result is always 4 chars long
}
