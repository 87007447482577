








































































































































































































































































































































































































































































































































import LoaderScreen from "@/components/LoaderScreen.vue";
import {
  FREQUENCIES,
  FrequencyIndex,
  freqSymbols
} from "@/calc/acoustic-constants";
import {
  OctoFrequential,
  RoomDirectionMultiFreq,
  TriDimensional
} from "@/calc/room";
import { DetailedResult } from "@/calc/sound-pressure-summed";
import { exportCSVTable } from "@/helper/excel-export";
import { Component, Vue } from "vue-property-decorator";
import { DetailedPropertyGroup } from "@/calc/result-log";
import { singleFreqResultLog } from "@/helper/detailed-result-log";
import {
  createBookmarkLink,
  encodeBookmark
} from "@/helper/settings-input-output";
import CurveGraph from "@/components/CurveGraph.vue";
import MultiFreqDiagram from "@/components/MultiFreqDiagram.vue";

import ValueDisplay from "@/components/ValueDisplay.vue";
import ValueInput from "@/components/ValueInput.vue";
import WallEditor from "@/components/WallEditor.vue";
import {
  dimensionalAverageAbsorption,
  dimensionalAverageScattering
} from "@/calc/area-assignment";
import RoomPreview from "@/components/RoomPreview.vue";
import ExplanationHint from "@/components/ExplanationHint.vue";
import {
  RTDeviationRule,
  getRTDeviationRules,
  dinTargetReverberationTime,
  SCENARIO_SYMBOLS,
  isCategoryB,
  dinCatBMinAVRatios,
  dinCatBAVRatiosPassed
} from "@/calc/din-requirements";
import { store, CalculationState } from "@/state/state";
import { maxTimeWindow, OctoValuePlot } from "@/helper/diagram";
import SummaryRoomDimensions from "@/components/SummaryRoomDimensions.vue";
import SummaryDinScenario from "@/components/SummaryDinScenario.vue";
import SummaryAbsorptionScattering from "@/components/SummaryAbsorptionScattering.vue";
import SummaryAtmosphere from "@/components/SummaryAtmosphere.vue";
import LocalStorageSave from "@/components/LocalStorageSave.vue";
import {
  Endpoint,
  HttpMethod,
  performCRUDRequest,
  RequestParams
} from "@/services/userServices";
import Navbar from "@/components/Navbar.vue";
import AuralizationPlayButton from "@/components/AuralizationPlayButton.vue";
import AuralizationDownloadButton from "@/components/AuralizationDownloadButton.vue";

@Component({
  components: {
    LoaderScreen,
    ValueDisplay,
    ValueInput,
    CurveGraph,
    MultiFreqDiagram,
    WallEditor,
    RoomPreview,
    ExplanationHint,
    SummaryRoomDimensions,
    SummaryDinScenario,
    SummaryAbsorptionScattering,
    SummaryAtmosphere,
    LocalStorageSave,
    Navbar,
    AuralizationDownloadButton,
    AuralizationPlayButton
  }
})
export default class Results extends Vue {
  private validationError: string = "";
  private tInput: number = 0.02;
  private frequencyIndexInput: FrequencyIndex = 3;
  private roomName: string = store.roomName;
  private loaded: Boolean = !store.loading;
  private localCalc: CalculationState = store.calc;
  public initialFurnishingOption: boolean =
    store.calc.furniture.length > 0 ? true : false;
  created() {
    if (store.loading) {
      store.loading = false;
      this.setupLoader();
    }
  }
  destroyed() {
    store.simulatedButtonPressed = false;
  }
  async setupLoader() {
    setTimeout(this.fetchResults, 1600);
  }

  async fetchResults() {
    try {
      const params: RequestParams = {
        method: HttpMethod.GET,
        endpoint: Endpoint.Results,
        UUID: store.roomUUID || "",
        data: undefined
      };
      const response: any = await performCRUDRequest<any>(params);

      store.setResult(response);
      this.loaded = true;
    } catch {
      this.$router.push({ name: "Calculation" });
    }
  }

  get calc(): CalculationState {
    return store.calc;
  }

  get result(): Readonly<DetailedResult> | null {
    return store.result;
  }

  /** Defines which v-expansion-panel is initially opened */
  private resultPanelState: number[] = [0, 1];

  get FREQUENCIES() {
    return FREQUENCIES;
  }
  get FREQUENCY_SYMBOLS() {
    return freqSymbols;
  }

  get roomMultiFreq(): TriDimensional<RoomDirectionMultiFreq> {
    return this.calc.roomSize.map((L, dIdx) => ({
      L,
      alpha: this.dimensionalAbsorption[dIdx],
      sc: this.dimensionalScattering[dIdx]
    })) as TriDimensional<RoomDirectionMultiFreq>;
  }

  get resultLog(): DetailedPropertyGroup[] {
    return singleFreqResultLog(
      this.calc.atmosphere,
      this.roomMultiFreq,
      this.tInput,
      (this.frequencyIndexInput + 1) as FrequencyIndex,
      this.result
    );
  }

  get dimensionalAbsorption(): TriDimensional<OctoFrequential<number>> {
    return dimensionalAverageAbsorption(
      this.calc.roomWalls,
      this.calc.roomSize,
      this.calc.furniture
    );
  }
  get dimensionalScattering(): TriDimensional<OctoFrequential<number>> {
    return dimensionalAverageScattering(
      this.calc.roomWalls,
      this.calc.roomSize
    );
  }

  get decayTimes() {
    return this.result?.octoFrequential.map(
      fResult => fResult.reverberationTimeZhou
    );
  }
  get decayTimesSabine() {
    return this.result?.octoFrequential.map(
      fResult => fResult.reverberationTimeSabine
    );
  }

  get AVRatios(): OctoFrequential<number> | null {
    if (!this.result) {
      return null;
    }
    return this.result.octoFrequential.map(
      fResult => fResult.AV
    ) as OctoFrequential<number>;
  }

  get AVRatiosPassed(): OctoFrequential<boolean | null> | null {
    if (!this.AVRatios || !this.dinCatBMinAVRatios) {
      return null;
    }
    return dinCatBAVRatiosPassed(this.AVRatios, this.dinCatBMinAVRatios);
  }

  get dinCatBMinAVRatios(): OctoFrequential<number | null> | null {
    if (!isCategoryB(this.calc.dinScenario)) {
      return null;
    }
    return dinCatBMinAVRatios(this.calc.roomSize[2], this.calc.dinScenario);
  }

  get octoFreqPlots(): OctoValuePlot[] {
    return [
      {
        values: this.decayTimes as OctoFrequential<number>,
        label: "reverberate (Zhou et al.)",
        drawingStyle: "line",
        bullet: "circle",
        dominant: true
      },
      {
        values: this.decayTimesSabine as OctoFrequential<number>,
        label: "classical method (C. Sabine)",
        drawingStyle: "line",
        bullet: "triangle",
        dominant: true
      }
    ];
  }

  /** Calculates a suitable time-window for displaying the slopes */
  get maxTimeWindow(): number {
    return maxTimeWindow(this.decayTimes);
  }
  get scenarioSymbols(): Readonly<string[]> {
    return SCENARIO_SYMBOLS;
  }
  get dinTargetRT(): number | null {
    return (
      this.result && // return null if this.result===null or next line
      dinTargetReverberationTime(this.result.V, this.calc.dinScenario)
    );
  }
  get RTDeviationRules(): OctoFrequential<RTDeviationRule> | null {
    return getRTDeviationRules(this.calc.dinScenario);
  }

  get dinMinToleratedRT() {
    if (this.dinTargetRT !== null && this.RTDeviationRules !== null) {
      return this.RTDeviationRules.map(
        rule => rule.minDeviation * (this.dinTargetRT || 0)
      ) as OctoFrequential<number>;
    } else {
      return null;
    }
  }
  get dinMaxToleratedRT() {
    if (this.dinTargetRT !== null && this.RTDeviationRules !== null) {
      return this.RTDeviationRules.map(
        rule => rule.maxDeviation * (this.dinTargetRT || 0)
      ) as OctoFrequential<number>;
    } else {
      return null;
    }
  }

  /** Calculates the scaled maximum time window for diagrams based on decay times.*/
  get scaledMaxTimeWindow(): number {
    const allDecayTimes = [
      ...(this.decayTimes || []),
      ...(this.decayTimesSabine || [])
    ];
    const highestYValue = Math.max(...allDecayTimes);
    let stepSize = 10;
    if (highestYValue > 15) {
      stepSize = 20;
    }
    const scaledMax = Math.ceil(highestYValue / stepSize) * stepSize;
    console.log("max", scaledMax);
    return scaledMax;
  }

  copyResultLogToClipBoard(numberFormat: "de-DE" | "en-US"): void {
    exportCSVTable(this.resultLog, numberFormat);
  }

  getBookmark() {
    return encodeBookmark(this.calc);
  }

  get bookmark(): string {
    if (!this.result) {
      return "";
    }
    return createBookmarkLink(this.calc);
  }

  reroute() {
    this.$router.push({ name: "tempView" });
  }
}
