<template>
  <div>
    <v-btn
      @click="handleDownloadClick"
      :loading="isLoading"
      style="background-color: white; margin: 0 auto; width: 100%"
      color="primary"
      text
      tile
      x-large
      elevation="3"
    >
      <span v-if="!isLoading">SAVE <v-icon>mdi-download</v-icon></span>
      <span v-else>Loading...</span>
    </v-btn>
  </div>
</template>

<script>
import { setupAudioComponent } from "../helper/auralization/downloadAuralizationSound";

export default {
  data() {
    return {
      isLoading: false
    };
  },
  methods: {
    async handleDownloadClick() {
      this.isLoading = true;
      try {
        await setupAudioComponent();
      } catch (error) {
        console.error("Error setting up audio component:", error);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>
.local-storage {
  position: absolute;
  top: 0;
  right: 0;
  width: 200px;
  margin: 0;
}
</style>
