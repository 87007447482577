




import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class ColorSwatch3D extends Vue {
  @Prop({ type: Number })
  dim!: 0 | 1 | 2;

  @Prop({ type: Number })
  dir!: 0 | 1;

  get color(): string {
    return (
      "#" +
      [0, 1, 2]
        .map(i => (i === this.dim ? (this.dir ? "8" : "d") : "3"))
        .join("")
    );
  }
}
