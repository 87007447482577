






















import { Component, Prop, Vue } from "vue-property-decorator";
import { store } from "@/state/state";
import { FurnitureDB } from "@/res/furniture-surfaces";
@Component({
  components: {}
})
export default class LocalStorageLoad extends Vue {
  @Prop()
  bookmark!: string;

  selectFile() {
    let fileInputElement = this.$refs.fileUpload as HTMLInputElement;
    fileInputElement.click();
  }

  addFile(x: any) {
    let fileReader = new FileReader();
    fileReader.onload = this.addToDB;
    fileReader.readAsText(x.target.files[0]);
  }

  addToDB(fileReader: any) {
    let db = JSON.parse(fileReader.target.result as string);
    if (db[3])
      db[3].forEach((ds: any) => {
        FurnitureDB.addFurniture(ds);
      });
    store.resetCalculation();
    store.bookmark = db[1];
    store.roomName = db[2];
    this.$router.push("/results");
  }
}
