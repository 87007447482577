












import { Component, Prop, Vue } from "vue-property-decorator";
import { AppState } from "@/state/state";
import RoomPreview from "./RoomPreview.vue";

@Component({
  components: {
    RoomPreview
  }
})
export default class SummaryRoomDimensions extends Vue {
  @Prop()
  roomSize!: AppState["calc"]["roomSize"];

  get dimensionsSummary() {
    return (
      this.roomSize.map(l => `${l.toFixed(1)}`).join(" ×\u202f") + "\u202fm³"
    );
  }
}
