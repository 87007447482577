import { DetailedPropertyGroup } from "../calc/result-log";

/** Creates a CSV plain text representation of the given result log and copies it to clipboard. The clipboard then can be pasted into Excel.*/
export function exportCSVTable(
  resultLog: DetailedPropertyGroup[],
  numberFormat: "de-DE" | "en-US" = "en-US"
): void {
  const CSVHeader = "Variable explanation\tReference\tVariable [unit]\tValue\n";
  const rows: [string, string, string, string][] = resultLog.flatMap(group => [
    [`####### ${group.heading} #######`, "", "", ""],
    ...group.properties.map<[string, string, string, string]>(property => [
      property.title,
      property.sciRef,
      property.symbol + (property.unit ? `[${property.unit}]` : ""),
      property.value.toLocaleString(numberFormat, {
        useGrouping: false,
        maximumFractionDigits: 20
      })
    ])
  ]);
  const CSVBody = rows.map(r => r.join("\t")).join("\n");
  navigator.clipboard.writeText(CSVHeader + CSVBody);
}
