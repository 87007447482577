















import { Component, Prop, Vue } from "vue-property-decorator";
import { ABSORPTION_DATABASE } from "@/res/materials-absorption";
import { store } from "@/state/state";
import { FURNITURE_DATABASE } from "@/res/furniture-surfaces";
@Component({
  components: {}
})
export default class LocalStorageSave extends Vue {
  @Prop()
  bookmark!: string;

  saveToDisk() {
    let data: string = JSON.stringify(this.getData());
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8, " + encodeURIComponent(data)
    );
    element.setAttribute("download", store.roomName + ".reverberate");
    document.body.appendChild(element);
    element.click();
  }
  getData() {
    return [
      Array.from(ABSORPTION_DATABASE.values()),
      this.bookmark,
      store.roomName,
      Array.from(FURNITURE_DATABASE.values()) //F&P Array
    ];
  }
}
