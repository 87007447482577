




























import { Component, Vue } from "vue-property-decorator";
import CurveGraph from "@/components/CurveGraph.vue";
import MultiFreqDiagram from "@/components/MultiFreqDiagram.vue";

import ValueDisplay from "@/components/ValueDisplay.vue";
import ValueInput from "@/components/ValueInput.vue";
import WallEditor from "@/components/WallEditor.vue";
import RoomPreview from "@/components/RoomPreview.vue";
import ExplanationHint from "@/components/ExplanationHint.vue";
import { store } from "@/state/state";

@Component({
  components: {
    ValueDisplay,
    ValueInput,
    CurveGraph,
    MultiFreqDiagram,
    WallEditor,
    RoomPreview,
    ExplanationHint
  }
})
export default class BookmarkError extends Vue {
  get error() {
    return store.error?.message || "";
  }
  clearErrorAndContinueToHome() {
    store.resetCalculation();
    this.$router.replace({ name: "Home" });
  }
}
