





































import { SixDirectional, TriDimensional } from "@/calc/room";
import { store } from "@/state/state";
import { Component, Prop, Vue } from "vue-property-decorator";
/** Coordinate wise multiplication of two 3D vectors. */
function multiply3D(
  a: TriDimensional<number>,
  b: TriDimensional<number>
): TriDimensional<number> {
  return [a[0] * b[0], a[1] * b[1], a[2] * b[2]];
}
/** Vector that ist used for isometric projection */
const isoVector: TriDimensional<[number, number]> = [
  [-1, 0.5],
  [1, 0.5],
  [0, -1]
];
/** Creates a isometric 2D projection of the given 3D vector */
function isometric(a: TriDimensional<number>): [number, number] {
  return [
    a[0] * isoVector[0][0] + a[1] * isoVector[1][0] + a[2] * isoVector[2][0],
    a[0] * isoVector[0][1] + a[1] * isoVector[1][1] + a[2] * isoVector[2][1]
  ];
}

type Face3D = TriDimensional<number>[];

type Face2D = [number, number][];

interface FaceSVG {
  /** original wall index before z-sorting */
  wIdx: number;
  /** string that can be applied to the SVG <polygon> element's "points" attribute */
  points: string;
}

/** Defines the faces of a standard 1x1x1m cube */
const defaultCube: SixDirectional<Face3D> = [
  [
    [0, 0, 0],
    [0, 0, 1],
    [0, 1, 1],
    [0, 1, 0]
  ],
  [
    [1, 0, 0],
    [1, 0, 1],
    [1, 1, 1],
    [1, 1, 0]
  ],
  [
    [0, 0, 0],
    [1, 0, 0],
    [1, 0, 1],
    [0, 0, 1]
  ],
  [
    [0, 1, 0],
    [1, 1, 0],
    [1, 1, 1],
    [0, 1, 1]
  ],
  [
    [0, 0, 0],
    [0, 1, 0],
    [1, 1, 0],
    [1, 0, 0]
  ],
  [
    [0, 0, 1],
    [0, 1, 1],
    [1, 1, 1],
    [1, 0, 1]
  ]
];

@Component({
  components: {}
})
export default class RoomPreview extends Vue {
  @Prop({ type: Array })
  size!: TriDimensional<number>;

  @Prop({ type: Number, default: null })
  wIdx!: 0 | 1 | 2 | 3 | 4 | 5 | null;

  @Prop({ type: Boolean, default: false })
  showLabels!: boolean;

  @Prop({ type: Boolean, default: false })
  fillAll!: boolean;

  /** scales the default cube to match the room dimensions */
  get sizedCuboid(): Face3D[] {
    return defaultCube.map(wall =>
      wall.map(point3d =>
        multiply3D(point3d as TriDimensional<number>, this.size)
      )
    );
  }
  /** projected into 2d space */
  get isometricCuboid(): Face2D[] {
    return this.sizedCuboid.map((wall: Face3D) =>
      wall.map(point3d => isometric(point3d))
    );
  }
  /** converts the polygons into svg <poligon> strings */
  get polygons(): FaceSVG[] {
    return this.isometricCuboid.map((wall, wIdx) => ({
      wIdx,
      points: wall.map(point2d => point2d.join(",")).join(" ")
    }));
  }
  /** corrects the drawing order of the polygons */
  get polygonsZSorted(): FaceSVG[] {
    const ps = this.polygons;
    store.walls_polygonsZSorted = [ps[0], ps[2], ps[4], ps[1], ps[3], ps[5]]; // Store polygons for later use in PDF generator
    store.walls_sizes = {
      width: this.isoBoundingBox[0][1] - this.isoBoundingBox[0][0],
      height: this.isoBoundingBox[1][1] - this.isoBoundingBox[1][0]
    };
    store.walls_specs.yMax = this.isoBoundingBox[1][1];
    store.walls_specs.xMax = this.isoBoundingBox[0][1];
    return [ps[0], ps[2], ps[4], ps[1], ps[3], ps[5]];
  }

  /**
   * 2D bounding box of the room preview used for viewport fitting
   * only works for this specific {@link isoVector}
   * @returns [[xMin, xMax], [yMin, yMax]]
   * */
  get isoBoundingBox(): [[number, number], [number, number]] {
    const padSize = this.size.map(L => L * 1.2);
    return [
      [padSize[0] * isoVector[0][0], padSize[1] * isoVector[1][0]],
      [
        padSize[2] * isoVector[2][1],
        padSize[1] * isoVector[1][1] + padSize[0] * isoVector[0][1]
      ]
    ];
  }

  get axisLabels(): {
    title: string;
    pos: [number, number];
  }[] {
    const margin = 1;
    return [
      {
        title: "width",
        pos: isometric([this.size[0] / 2, -margin, this.size[2] + margin])
      },
      {
        title: "length",
        pos: isometric([-margin, this.size[1] / 2, this.size[2] + margin])
      },
      {
        title: "height",
        pos: isometric([this.size[0], -margin, this.size[2] / 2])
      }
    ];
  }
}
