import { p_refSquared } from "./acoustic-constants";

/**
 * Converts the given squared sound pressure to a sound pressure level SPL in dB
 * using the reference sound pressure p_ref=2e-5 Pa.
 * @param squaredSoundPressure in Pa^2
 */

export function soundPressureLevel(squaredSoundPressure: number): number {
  return relativeSoundPressureLevel(squaredSoundPressure, p_refSquared);
}

/**
 * Converts the given squared sound pressure to a relative sound pressure
 * level SPL in dB using the given squared sound pressure reference.
 * Use the initial squared sound pressure of a decay curve to
 * get dB values starting from 0dB.
 * @param squaredSoundPressure in Pa^2
 * @param squaredReferenceSP in Pa^2
 */
export function relativeSoundPressureLevel(
  squaredSoundPressure: number,
  squaredReferenceSP: number
): number {
  return 10 * Math.log10(squaredSoundPressure / squaredReferenceSP);
}
