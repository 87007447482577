
















































import { checkRoomNameRules, roomNameRules } from "@/helper/validation-rules";
import { Project, ProjectDB } from "@/res/project";
import { SharedFunctions, store } from "@/state/state";
import { Component, Vue } from "vue-property-decorator";

@Component({ components: {} })
export default class PromptDuplicateProject extends Vue {
  async created() {
    SharedFunctions.duplicateProject = this.duplicateProject;
  }
  private duplicateOpen = false;
  private duplicateText: string = "";
  async promptDuplicateProject(message: string): Promise<string | null> {
    this.focusInput();
    return new Promise(resolve => {
      this.duplicateText = message;
      this.duplicateOpen = true;
      const closeDialog = (response: any) => {
        this.duplicateOpen = false;
        resolve(response ? this.duplicateText : null);
      };
      this.$once("confirmed", closeDialog);
    });
  }

  async duplicateProject(project: Project): Promise<boolean> {
    let newProjectName: string | null = await this.promptDuplicateProject(
      project.title
    );
    if (newProjectName) {
      this.$emit("confirmed", true);
      this.duplicateOpen = false;
      store.setCalc(project.projectData);
      store.roomUUID = null; // No UUID means new Project!
      store.roomName = newProjectName;
      store.roomDescription = "not implemented (todo)";
      return true;
    }
    return false;
  }

  roomNameValidated() {
    return checkRoomNameRules(this.duplicateText, false);
  }

  async saveDuplicatedProject() {
    const requestBody: Project = {
      uuid: store.roomUUID,
      title: this.duplicateText,
      description: store.roomDescription,
      projectData: store.calc
    };
    let response: Project;
    if (checkRoomNameRules(requestBody.title, false)) {
      response = await ProjectDB.addProject(requestBody); // POST
      store.roomUUID = response.uuid;
    }
  }

  focusInput() {
    setTimeout(() => {
      (this.$refs.textInput as HTMLInputElement)?.focus();
    }, 200);
  }

  data() {
    return {
      rules: roomNameRules(false)
    };
  }
}
