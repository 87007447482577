import { FurnitureConfiguration } from "@/state/state";
import { Atmosphere, kelvin } from "./acoustic-constants";
import { RoomSingleFreq } from "./room";

/** Default ambient atmospheric temperature, in °C */
export const default_theta = 20;

/** Default ambient atmospheric temperature, in K */
export const default_Tc = kelvin(default_theta);

/** Default relative humidity, in % */
export const default_hum_rel = 50;

/** Default ambient atmospheric pressure, in Pa */
export const default_pa = 1012.3;
// export const default_pa = 101230;

/** Returns a new mutable Atmosphere object to be used as preset values for the user input form. */
export function createExampleAtmosphere(): Atmosphere {
  return {
    T: default_Tc,
    hum_rel: default_hum_rel,
    pa: default_pa
  };
}

/** Default room length in dimension x */
export const default_Lx = 6.8;

/** Default room length in dimension x */
export const default_Ly = 10;

/** Default room length in dimension x */
export const default_Lz = 4.8;

/** Returns a new mutable Room object to be used as preset values for the user input form. */
export function createExampleRoom(): RoomSingleFreq {
  return [
    {
      sc: 0.04,
      alpha: 0.02,
      L: default_Lx
    },
    {
      sc: 0.04,
      alpha: 0.03,
      L: default_Ly
    },
    {
      sc: 0.04,
      alpha: 0.6,
      L: default_Lz
    }
  ];
}

/** Returns an example furniture configuration. */
/** Used in state.ts AppState */
export function createExampleFurniture(): FurnitureConfiguration[] {
  return [];
}
