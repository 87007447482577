






























































import { Component, VModel, Vue } from "vue-property-decorator";
import { Ripple } from "vuetify/lib/directives";
import { CalculationState, FurnitureConfiguration, store } from "@/state/state";
import RoomPreview from "@/components/RoomPreview.vue";
import WallSelectionComponent from "@/components/WallSelectionComponent.vue";
import FurnitureInput from "./FurnitureInput.vue";

@Component({
  components: {
    RoomPreview,
    WallSelectionComponent,
    FurnitureInput
  },
  directives: { Ripple }
})
export default class FurnishedObjects extends Vue {
  @VModel({ type: Array })
  private furniture!: FurnitureConfiguration[];

  private localCalc: CalculationState = store.calc;

  addAssignment() {
    this.furniture.push({
      quantity: 1,
      uuid: "",
      position: 6
    });
  }
  deleteAssignment(idx: number) {
    this.furniture.splice(idx, 1);
  }
  data() {
    return {
      selectedItem: null
    };
  }
  get roomSize() {
    return this.localCalc.roomSize;
  }
  set roomSize(roomSize) {
    console.log("set roomSize(r before", this.localCalc);
    this.localCalc = { ...this.localCalc, roomSize }; // immutable
    console.log("set roomSize(r", this.localCalc);
  }
  get roomWalls() {
    return this.localCalc.roomWalls;
  }

  set roomWalls(roomWalls) {
    console.log("set roomWalls( before", this.localCalc);
    this.localCalc = { ...this.localCalc, roomWalls }; // immutable
    console.log("set roomWalls(", this.localCalc);
  }
  editRoom(index: number) {
    // Emit an event to notify the parent component about the edit action
    this.$emit("edit-room", index);
  }
}
