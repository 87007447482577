import { DetailedResult } from "@/calc/sound-pressure-summed";
import { store } from "../../state/state";

let audioSource: AudioBufferSourceNode | null = null;
let audioContext: AudioContext | null = null;

export async function setupAudioComponent(): Promise<void> {
  return new Promise(resolve => {
    const data: DetailedResult = store.result!;
    const zhouTimes = data.octoFrequential.map(
      item => item.reverberationTimeZhou
    );
    zhouTimes.shift();
    zhouTimes.pop();
    const sumOfZhouTimes = zhouTimes.reduce((sum, time) => sum + time, 0);
    const averageDuration = sumOfZhouTimes / zhouTimes.length;
    let lowPassFrequency = 5620;
    let BP_Q = 3;
    let audioBuffer: AudioBuffer;
    let dryGain: GainNode;
    let wetGain: GainNode;
    let BP_Filter: BiquadFilterNode;
    let lowpassFilter: BiquadFilterNode;
    let dry: number;
    let wet: number;
    const reverbs: { [key: number]: ConvolverNode } = {};
    const audioFile = require("@/assets/wav/davide-voice.wav");
    let audioContext: AudioContext = new AudioContext();
    fetch(audioFile)
      .then(response => response.arrayBuffer())
      .then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer))
      .then(decodedBuffer => {
        audioBuffer = decodedBuffer;

        if (audioBuffer.numberOfChannels === 1) {
          const newBuffer = audioContext.createBuffer(
            2,
            audioBuffer.length,
            audioBuffer.sampleRate
          );
          const channel1Data = audioBuffer.getChannelData(0);
          const channel2Data = audioBuffer.getChannelData(0);
          newBuffer.copyToChannel(channel1Data, 0);
          newBuffer.copyToChannel(channel2Data, 1);
          audioBuffer = newBuffer;
        }

        if (audioBuffer) {
          renderWAV();
        }
      });

    function generateImpulseResponse(durationInSeconds: number): AudioBuffer {
      const impulseLength = Math.ceil(
        audioContext.sampleRate * durationInSeconds
      );
      const impulseBuffer = audioContext.createBuffer(
        2,
        impulseLength,
        audioContext.sampleRate
      );
      let maxAmplitude = 0;
      for (
        let channel = 0;
        channel < impulseBuffer.numberOfChannels;
        channel++
      ) {
        const data = impulseBuffer.getChannelData(channel);
        for (let i = 0; i < impulseLength; i++) {
          const decayFactor = Math.exp(-6 * (i / impulseLength));
          data[i] = decayFactor * (Math.random() * 2 - 1);
          maxAmplitude = Math.max(maxAmplitude, Math.abs(data[i]));
        }
      }
      const scalingFactor = 1 / maxAmplitude;
      for (
        let channel = 0;
        channel < impulseBuffer.numberOfChannels;
        channel++
      ) {
        const data = impulseBuffer.getChannelData(channel);
        for (let i = 0; i < impulseLength; i++) {
          data[i] *= scalingFactor;
        }
      }
      return impulseBuffer;
    }
    function getLongestReverbDuration(): number {
      return Math.max(...zhouTimes);
    }
    const longestReverbDuration = getLongestReverbDuration();
    generateImpulseResponse(longestReverbDuration);
    function createReverbs(
      aCTX: BaseAudioContext,
      reverbTimesInSeconds: number[]
    ): void {
      reverbTimesInSeconds.forEach((duration, i) => {
        if (duration <= 0) duration = 0.01;
        const key = (125 * Math.pow(2, i)) as number;
        reverbs[key] = aCTX.createConvolver();
        reverbs[key].buffer = generateImpulseResponse(duration);
      });
    }
    // biquadfilternode function taking param: frequency, type, Q
    function createBiquadFilterNode(
      frequency: number,
      aCTX: BaseAudioContext
    ): BiquadFilterNode {
      const filter = aCTX.createBiquadFilter();
      filter.type = "bandpass";
      filter.frequency.value = frequency;
      filter.Q.value = BP_Q;
      return filter;
    }

    function createLowPassFilterNode(aCTX: BaseAudioContext): BiquadFilterNode {
      const lowPassFilter = aCTX.createBiquadFilter();
      lowPassFilter.type = "lowpass";
      lowPassFilter.Q.value = 1;
      lowPassFilter.frequency.value = lowPassFrequency;
      return lowPassFilter;
    }
    function calcDryWet(r: number): void {
      const T = averageDuration;
      const V = data.V;
      // Revised Xiaoru's formula:
      const E_direkt = 1 / (4 * Math.PI * Math.pow(r, 2));
      const E_diffus = (4 * T) / (0.161 * V);
      const totalEnergy = E_direkt + E_diffus;
      dry = E_direkt / totalEnergy;
      wet = E_diffus / totalEnergy;
      dry = Math.max(0, Math.min(1, dry)); // Clamp dry value between 0 and 1
      wet = Math.max(0, Math.min(1, wet)); // Clamp wet value between 0 and 1
    }
    function renderWAV(): void {
      if (!audioBuffer || !reverbs) {
        return;
      }

      const reverbDurationsInSeconds = zhouTimes;
      const longestReverb = Math.max(...reverbDurationsInSeconds);
      const offlineContextLength =
        audioBuffer.length + audioBuffer.sampleRate * longestReverb;
      const offlineContext = new OfflineAudioContext(
        audioBuffer.numberOfChannels,
        offlineContextLength,
        audioBuffer.sampleRate
      );

      const source = offlineContext.createBufferSource();
      source.buffer = audioBuffer;

      dryGain = offlineContext.createGain();
      wetGain = offlineContext.createGain();

      calcDryWet(2);
      dryGain.gain.value = dry;
      wetGain.gain.value = wet;

      source.connect(dryGain);

      createReverbs(offlineContext, reverbDurationsInSeconds);

      // Create BP_Filter and lowpassFilter within the same offline context
      BP_Filter = createBiquadFilterNode(125, offlineContext);
      lowpassFilter = createLowPassFilterNode(offlineContext);

      for (let [key, value] of Object.entries(reverbs)) {
        const numericKey = parseInt(key);
        if (isNaN(numericKey) || !value) continue;
        source.connect(value);
        value.connect(BP_Filter);
      }

      BP_Filter.connect(lowpassFilter);
      lowpassFilter.connect(wetGain);

      dryGain.connect(offlineContext.destination);
      wetGain.connect(offlineContext.destination);

      source.start(0);

      offlineContext.startRendering().then(renderedBuffer => {
        playAudio(renderedBuffer);
      });
    }

    function playAudio(audioBuffer: AudioBuffer): void {
      audioSource = audioContext.createBufferSource();
      audioSource.buffer = audioBuffer;
      audioSource.connect(audioContext.destination);
      audioSource.onended = handleAudioEnded;
      audioSource.start();
      resolve();
    }

    function handleAudioEnded(): void {
      if (audioSource) {
        audioSource.disconnect();
        audioSource = null;
      }
      window.dispatchEvent(new Event("audioEnded"));
    }
  });
}

export function stopAudio(): void {
  if (audioSource) {
    audioSource.stop();
    audioSource.disconnect();
    audioSource = null;
  }

  if (audioContext) {
    audioContext.close().then(() => {
      audioContext = null;
    });
  }
}
