































import RoomPreview from "@/components/RoomPreview.vue";
import { store } from "@/state/state";
import { Vue, VModel, Component } from "vue-property-decorator";

@Component({
  components: {
    RoomPreview
  }
})
export default class WallSelection extends Vue {
  @VModel({ type: Number })
  inputValue!: number;

  data() {
    const roomSize = store.calc.roomSize;
    return {
      selectedValue: this.inputValue,
      dropdownOptions: [
        { label: "Option 1", size: roomSize, wIdx: 0 },
        { label: "Option 2", size: roomSize, wIdx: 1 },
        { label: "Option 3", size: roomSize, wIdx: 2 },
        { label: "Option 4", size: roomSize, wIdx: 3 },
        { label: "Option 5", size: roomSize, wIdx: 4 },
        { label: "Option 6", size: roomSize, wIdx: 5 },
        { label: "Option 7", size: roomSize, wIdx: 6 }
      ]
    };
  }
}
