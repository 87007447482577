

























import { OctoFrequential } from "@/calc/room";
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  bulletType,
  BULLET_STYLES,
  DRAWING_STYLES,
  getMultiFreqLine,
  svgPathFromPoints
} from "@/helper/diagram";

/** id that is unique per component instance */
let uid = 0;

/** Draws a octo frequency SVG line inside a MultiFreqDiagram */
@Component({
  components: {}
})
export default class MultiFreqLine extends Vue {
  /** id used to link the svg clipping object */
  pointIndicatorID: string = `point-indicator_${uid++}`; // increase counter to ensure unique ids

  @Prop()
  values!: Readonly<OctoFrequential<number>>;

  @Prop()
  bullet!: bulletType | null;

  @Prop({ type: Boolean, required: false, default: false })
  dominant!: boolean;

  @Prop({ type: Function })
  projectX!: (x: number) => number;

  @Prop({ type: Function })
  projectY!: (y: number) => number;

  get bulletPath(): string | null {
    if (!this.bullet) {
      return null;
    }
    const style = BULLET_STYLES[this.bullet] || BULLET_STYLES.circle;
    return style.bullet;
  }

  get points(): OctoFrequential<[number, number]> {
    return getMultiFreqLine(
      this.values as OctoFrequential<number>,
      this.projectX,
      this.projectY
    );
  }
  /** Returns a path definition string for the SVG <path> element */
  get polylinePath(): string {
    return svgPathFromPoints(this.points);
  }

  get polylineStyle(): any {
    return {
      strokeWidth: this.dominant ? 2 : 1,
      stroke: DRAWING_STYLES.line.stroked ? DRAWING_STYLES.line.color : "none",
      fill: DRAWING_STYLES.line.filled ? DRAWING_STYLES.line.color : "none"
    };
  }
}
