<template>
  <v-container fluid class="ma-0 pa-0 loader-wrapper">
    <v-row align="center" justify="center">
      <v-col color="primary">
        <div>
          <v-card color="primary" class="ma-0 py-9" dark tile>
            <div class="loader">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <p class="text-primary">{{ text }}</p>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "LoaderScreen",
  props: {
    active: Boolean,
    text: String
  }
};
</script>

<style scoped>
p {
  font-size: 1rem;
  font-weight: lighter;
  margin: 0;
  letter-spacing: 1px;
}

.loader-wrapper {
  text-align: center;
}

.loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0;
}
.loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 3px solid #116655;
  border-radius: 47%;
  animation: loader 1s cubic-bezier(1, 1, 1, 1) infinite;
  border-color: rgba(255, 255, 255, 0.1) rgba(255, 255, 255, 0.1)
    rgba(255, 255, 255, 0.1) rgba(255, 255, 255, 0.5);
  box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.025) inset;
}
.loader div:nth-child(1) {
  animation-delay: -0s;
}
.loader div:nth-child(2) {
  animation-delay: -0.05s;
}
.loader div:nth-child(3) {
  animation-delay: -0.1s;
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
