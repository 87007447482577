import {
  Endpoint,
  HttpMethod,
  RequestParams,
  performCRUDRequest
} from "@/services/userServices";
import { CalculationState } from "@/state/state";

export interface Project {
  /** Should be a unique 28-bit sequence that never changes. Will not be visible to the user. */
  uuid: string | null;
  /** Short unique project title that will be shown in the user interface. */
  title: string;
  /** Project description that will be shown in the project details. */
  description: string;
  /** All the data in a project */
  projectData: CalculationState;
}

export type ProjectDatabase = Map<Project["uuid"], Project>;

let PROJECT_OBJECTS: Project[] = [];
export let PROJECT_DATABASE: ProjectDatabase = new Map();

export function initProjects(materialData: Project[]) {
  PROJECT_OBJECTS = materialData;
  PROJECT_DATABASE = new Map(PROJECT_OBJECTS.map(m => [m.uuid, m]));
}

/** POST */
export var ProjectDB = {
  async addProject(newProject: Project): Promise<any> {
    try {
      const params: RequestParams = {
        method: HttpMethod.POST,
        endpoint: Endpoint.Project,
        UUID: undefined,
        data: newProject
      };
      const response: Project = await performCRUDRequest<Project>(params);
      this.add_PROJECT(response);
      return response;
    } catch (error) {
      console.error("Error saving new material:", error);
      throw error; //TODO: Remove
    }
  },
  add_PROJECT(newProject: Project) {
    PROJECT_OBJECTS.push(newProject);
    PROJECT_DATABASE.set(newProject.uuid, newProject);
    // PROJECT_DATABASE = new Map(PROJECT_OBJECTS.map(m => [m.uuid, m]));
  },

  async updateProject(UUID: string, project: Project): Promise<any> {
    try {
      const params: RequestParams = {
        method: HttpMethod.PUT,
        endpoint: Endpoint.Project,
        UUID: UUID,
        data: project
      };
      const response: Project = await performCRUDRequest<Project>(params);
      this.update_PROJECT(response);
      return response;
    } catch (error) {
      console.error("Error saving new material:", error);
      throw error; //TODO: Remove
    }
  },
  update_PROJECT(project: Project) {
    PROJECT_DATABASE.set(project.uuid, project);
    PROJECT_OBJECTS = Array.from(PROJECT_DATABASE.values());
  },

  async deleteProjectByUUID(UUID: string): Promise<any> {
    try {
      const params: RequestParams = {
        method: HttpMethod.DELETE,
        endpoint: Endpoint.Project,
        UUID: UUID,
        data: undefined
      };
      const response: Project = await performCRUDRequest<Project>(params);
      this.remove_PROJECT(response.uuid);
      return response;
    } catch (error) {
      console.error("Error saving new material:", error);
      throw error; //TODO: Remove
    }
  },
  remove_PROJECT(UUID: string | null) {
    PROJECT_DATABASE.delete(UUID);
    PROJECT_OBJECTS = Array.from(PROJECT_DATABASE.values());
  }
};
