import { W_0 } from "./acoustic-constants";
import { DecayFunction } from "./decay-time";

/**
 * Initial directional squared sound pressure for directions i=x,y,z.
 *
 * ^= p^2_{i,1} from (Zhou et al., 2021, Eq. 8)
 *
 * ^= p^2_{i0} from (Zhou et al., 2021, Eq. 16)
 * @param room Room
 * @param direction Direction i
 */
export function initialDirectionalSquaredSoundPressure(
  L_i: number,
  m_f: number,
  BR_ie: number,
  alpha_ie: number,
  SC_ie: number,
  rho_0: number,
  c_0: number
): number {
  return (
    ((2 * rho_0 * c_0 * W_0) / (4 * Math.PI * L_i * L_i)) *
    Math.exp(-m_f * L_i + Math.log((1 - BR_ie) * (1 - alpha_ie) * (1 - SC_ie)))
  );
}

/**
 * Returns the number of wall reflections in the given room-dimension after time t (Zhou et al., 2021, Eq. 14)
 * @param L_i length of the room in the respective direction
 * @param t time
 */
export function numberOfWallReflections(
  L_i: number,
  c_0: number,
  t: number
): number {
  return (c_0 * t) / L_i;
}

/**
 * Directional squared sound pressure after time t.
 * p_i^2(t) from (Zhou et al., 2021, Eq. 16)
 */
export function directionalSquaredSoundPressure(
  L_i: number,
  c_0: number,
  P2_i0: number,
  mL_i: number,
  BR_ie: number,
  alpha_ie: number,
  SC_ie: number
): DecayFunction {
  const expFactor =
    (-c_0 / L_i) *
    (mL_i - Math.log((1 - BR_ie) * (1 - alpha_ie) * (1 - SC_ie)));

  return time => P2_i0 * Math.exp(time * expFactor);
}
