








import { Component, Prop, Vue } from "vue-property-decorator";
import { Material } from "@/res/materials-absorption";
import {
  materialStripePattern,
  scatteringPattern
} from "@/helper/material-pattern";

@Component({
  components: {}
})
export default class AbsorptionScatteringThumbnail extends Vue {
  @Prop({ type: String })
  type!: "absorption" | "scattering";

  @Prop()
  matValues!: Readonly<Material["values"]>;

  get patternFunc(): (
    values: Material["values"]
  ) => Partial<CSSStyleDeclaration> {
    return this.type === "scattering"
      ? scatteringPattern
      : materialStripePattern;
  }

  get pattern(): Partial<CSSStyleDeclaration> {
    return this.patternFunc(this.matValues);
  }
}
