












































































import { DecayFunction } from "@/calc/decay-time";
import { Component, Prop, Vue } from "vue-property-decorator";

import SVGRTIndicator from "@/components/SVGRTIndicator.vue";
import DiagramLegend from "@/components/DiagramLegend.vue";
import {
  SVGViewBox,
  calculateSVGViewbox,
  calculateAxisMarks,
  gridXPath,
  gridYPath,
  getLegendPositions,
  LegendItem,
  getLegendHeight
} from "@/helper/diagram";

/** id that is unique per component instance */
let uid = 0;

@Component({
  components: { SVGRTIndicator, DiagramLegend }
})
export default class CurveGraph extends Vue {
  @Prop({ type: Function })
  decayFunction!: DecayFunction;

  @Prop({ default: null })
  reverberationTime!: number | null;

  @Prop({ default: 60 })
  rTReference!: number | null;

  @Prop({ type: Boolean, default: false })
  showLegend!: boolean;

  @Prop({ type: Number })
  xMax!: number;

  @Prop({ type: Number })
  xRes!: number; // put marks every ? units

  width = 400;
  height = 300;
  margin = 50;
  xMin = 0; // in seconds
  yMin = -80; // in db
  yMax = 0; // in db
  yRes = 10; // put marks every ? units

  get xScale() {
    return Math.floor(this.width / (this.xMax - this.xMin));
  }
  get yScale() {
    return (
      Math.floor((this.height / (this.yMax - this.yMin)) * this.yRes) /
      this.yRes
    );
  }
  get SVGViewbox(): SVGViewBox {
    return calculateSVGViewbox(
      this.width,
      this.height,
      this.margin,
      this.legendHeight
    );
  }
  get plotPath() {
    let delimiter = "M";
    let svgPath = "";
    // iterate in viewport coords so every pixel gets a point:
    for (let pX = 0; pX <= this.width; pX += 1) {
      const t = pX / this.xScale + this.xMin;
      const pY = this.projectY(this.decayFunction(t));
      svgPath += `${delimiter}${pX.toFixed(3)} ${pY.toFixed(3)} `;
      delimiter = "L";
    }
    return svgPath;
  }
  get xAxisMarks() {
    return calculateAxisMarks(this.xMin, this.xMax, this.xRes);
  }
  get yAxisMarks() {
    return calculateAxisMarks(this.yMin, this.yMax, this.yRes);
  }
  get gridXPath() {
    return gridXPath(
      this.xAxisMarks,
      this.yMin,
      this.yMax,
      this.projectX,
      this.projectY
    );
  }

  get gridYPath() {
    return gridYPath(
      this.yAxisMarks,
      this.xMin,
      this.xMax,
      this.projectX,
      this.projectY
    );
  }

  /** id used to link the svg clipping object */
  clipId: string = `clip_${uid++}`; // increase counter to ensure unique ids

  projectX(t: number): number {
    return (t - this.xMin) * this.xScale;
  }
  projectY(y: number): number {
    return (this.yMax - y) * this.yScale;
  }
  get legendItems(): LegendItem[] {
    const items: LegendItem[] = [
      {
        label: "Decay (Zhou et al. 2021)",
        drawingStyle: "plot",
        bullet: null,
        dominant: true
      }
    ];
    if (this.reverberationTime !== null && this.rTReference !== null) {
      items.push(
        {
          label: "Unprojected T30 Reference",
          drawingStyle: "realRT",
          bullet: "circle",
          dominant: true
        },
        {
          label: "T30 (Zhou et al. 2021)",
          drawingStyle: "virtualRT",
          bullet: "circle",
          dominant: false
        }
      );
    }
    return items;
  }
  get legendPositions(): [number, number][] {
    return getLegendPositions(this.legendItems.length, this.width, 2);
  }
  get legendHeight(): number {
    return this.showLegend ? getLegendHeight(this.legendItems.length, 2) : 0;
  }
  formatNum(x: number): string {
    return x >= 0 ? `${x}` : `−${-x}`;
  }
}
