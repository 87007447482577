



















































































import {
  Atmosphere,
  FREQUENCIES,
  FREQUENCY,
  FrequencyIndex
} from "@/calc/acoustic-constants";
import {
  createExampleAtmosphere,
  createExampleRoom
} from "@/calc/default-inputs";
import {
  RoomSingleFreq,
  RoomDirectionMultiFreq,
  TriDimensional
} from "@/calc/room";
import {
  summedSquaredSoundPressure,
  DetailedResult
} from "@/calc/sound-pressure-summed";
import { exportCSVTable } from "@/helper/excel-export";
import { Component, Vue } from "vue-property-decorator";
import { DetailedPropertyGroup } from "@/calc/result-log";
import { singleFreqResultLog } from "@/helper/detailed-result-log";

import ValueDisplay from "@/components/ValueDisplay.vue";
import ValueInput from "@/components/ValueInput.vue";

@Component({
  components: { ValueDisplay, ValueInput }
})
export default class Evolution4 extends Vue {
  private validationError: string = "";
  private atmosphereInput: Atmosphere = createExampleAtmosphere();
  private roomInput: RoomSingleFreq = createExampleRoom();
  private timeInput: number = 0.1;
  private frequencyIndexInput: FrequencyIndex = 3;
  private result: DetailedResult | null = null;

  get frequencyValue(): FREQUENCY {
    return FREQUENCIES[this.frequencyIndexInput];
  }

  get FREQUENCIES() {
    return FREQUENCIES;
  }

  /** Mocks a multi frequential room because this evolution only has single freq input */
  get roomMultiFreq(): TriDimensional<RoomDirectionMultiFreq> {
    return this.roomInput.map(d => ({
      L: d.L,
      alpha: FREQUENCIES.map(() => d.alpha),
      sc: FREQUENCIES.map(() => d.sc)
    })) as TriDimensional<RoomDirectionMultiFreq>;
  }

  get resultLog(): DetailedPropertyGroup[] {
    return singleFreqResultLog(
      this.atmosphereInput,
      this.roomMultiFreq,
      this.timeInput,
      this.frequencyIndexInput,
      this.result
    );
  }

  calculate() {
    this.result = summedSquaredSoundPressure(
      this.atmosphereInput,
      this.roomMultiFreq
    );
  }

  copyResultLogToClipBoard(numberFormat: "de-DE" | "en-US"): void {
    exportCSVTable(this.resultLog, numberFormat);
  }
}
