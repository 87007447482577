









import { Component, Prop, Vue } from "vue-property-decorator";
import { AppState } from "@/state/state";

@Component({
  components: {}
})
export default class SummaryAtmosphere extends Vue {
  @Prop()
  atmosphere!: AppState["calc"]["atmosphere"];

  get summaries() {
    return [
      {
        icon: "mdi-thermometer",
        text: `${parseFloat((this.atmosphere.T - 273.15).toFixed(4))}°C`
      },
      { icon: "mdi-water", text: `${this.atmosphere.hum_rel}\u202f%` },
      { icon: "mdi-gauge", text: `${this.atmosphere.pa}\u202fhPa` }
    ];
  }
}
