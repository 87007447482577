


















































































































import { Component, Vue } from "vue-property-decorator";
import {
  Furniture,
  FurnitureDB,
  FURNITURE_DATABASE
} from "@/res/furniture-surfaces";
import { CreateMaterialRequest } from "@/res/materials-absorption";
@Component({
  components: {}
})
export default class FurnitureInputAdd extends Vue {
  private dialog: boolean = false;
  private type: "New" | "Edit" = "New";
  private Hz: string[] = ["62.5", "125", "250", "500", "1k", "2k", "4k", "8k"];
  public mat: CreateMaterialRequest = {
    title: "",
    values: [0, 0, 0, 0, 0, 0, 0, 0],
    description: "",
    source: ""
  } as CreateMaterialRequest;
  private values: string[] = Array(8).fill("");

  async addCustomFurniture() {
    if (!this.formIsValid()) return;
    this.dialog = false;
    const paddedValues = [...this.values.map(v => +v), 0, 0, 0, 0].slice(0, 8);

    const newFurniture: CreateMaterialRequest = {
      title: this.mat.title,
      values: paddedValues as [
        number,
        number,
        number,
        number,
        number,
        number,
        number,
        number
      ],
      description: this.mat.description,
      source: this.mat.source,
      uuid: this.mat.uuid
    };

    let response: any;
    if (this.type === "New") {
      response = await FurnitureDB.addFurniture(newFurniture);
      if (response) {
        FurnitureDB.add_FURNITURE_OBJECT(response);
        FURNITURE_DATABASE.set(response.uuid, response);
      }
    } else {
      response = await FurnitureDB.updateFurniture(
        newFurniture,
        newFurniture.uuid
      );
      if (response) {
        FurnitureDB.add_FURNITURE_OBJECT(response);
        FURNITURE_DATABASE.set(response.uuid, response);
      }
    }
    this.$emit("refreshFurnitureList", response.uuid);
    this.resetForm();
  }

  form = () =>
    this.$refs.form as Vue & {
      validate: () => boolean;
      reset: () => null;
      resetValidation: () => null;
    };

  formIsValid() {
    return this.form().validate();
  }

  resetForm() {
    if (this.form()) {
      this.form().reset();
      this.form().resetValidation();
    }
  }

  openNewFurniture() {
    this.type = "New";
    this.dialog = true;
    this.resetForm();
  }

  openEditFurniture(furniture: Furniture) {
    this.type = "Edit";
    this.dialog = true;
    this.mat = { ...furniture };
    this.values = Array.from(furniture.values.map(v => v.toString()));
  }

  close() {
    this.resetForm();
    this.dialog = false;
  }

  data() {
    return {
      currentFurnitureID: 0,
      valid: false,
      titleRules: [
        (v: any) => !!v || "Name is required",
        (v: any) =>
          (v && v.length <= 80) || "Name must be less than 80 characters"
      ],
      descriptionRules: [
        (v: any) => !!v || "Description text is required",
        (v: any) =>
          (v && v.length <= 255) ||
          "Description must be less than 256 characters"
      ],
      sourceRules: [
        (v: any) => !!v || "Source reference is required",
        (v: any) =>
          (v && v.length <= 80) ||
          "Source reference text must be less than 80 characters"
      ],
      valuesRules: [
        (v: any) => !!v || "0-20",
        (v: any) => (v && v >= 0 && v <= 20) || "0-20",
        (v: any) => (v && v.length <= 6) || "<6"
      ]
    };
  }
}
