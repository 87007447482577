
































































































































































































































































import { FREQUENCIES, freqSymbols } from "@/calc/acoustic-constants";
import { OctoFrequential, TriDimensional } from "@/calc/room";
import { Component, Prop, Vue } from "vue-property-decorator";
import CurveGraph from "@/components/CurveGraph.vue";
import MultiFreqDiagram from "@/components/MultiFreqDiagram.vue";
import Results from "@/views/Results.vue";
import FurnitureInput from "@/components/FurnitureInput.vue";

import ValueDisplay from "@/components/ValueDisplay.vue";
import ValueInput from "@/components/ValueInput.vue";
import WallEditor, { WallEditorState } from "@/components/WallEditor.vue";
import RoomPreview from "@/components/RoomPreview.vue";
import ExplanationHint from "@/components/ExplanationHint.vue";
import {
  DIN_SCENARIOS,
  SCENARIO_IDS,
  SCENARIO_SYMBOLS,
  SCENARIO_TITLES
} from "@/calc/din-requirements";
import { CalculationState, SharedFunctions, store } from "@/state/state";
import SummaryRoomDimensions from "@/components/SummaryRoomDimensions.vue";
import SummaryDinScenario from "@/components/SummaryDinScenario.vue";
import SummaryAbsorptionScattering from "@/components/SummaryAbsorptionScattering.vue";
import SummaryAtmosphere from "@/components/SummaryAtmosphere.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import FurnishedObjects from "@/components/FurnishedObjects.vue";
import { Project, ProjectDB, PROJECT_DATABASE } from "@/res/project";
import Navbar from "@/components/Navbar.vue";
import { checkRoomNameRules, roomNameRules } from "@/helper/validation-rules";

@Component({
  components: {
    ValueDisplay,
    ValueInput,
    CurveGraph,
    MultiFreqDiagram,
    WallEditor,
    RoomPreview,
    SummaryRoomDimensions,
    SummaryDinScenario,
    SummaryAbsorptionScattering,
    SummaryAtmosphere,
    ExplanationHint,
    Results,
    FurnitureInput,
    MaterialInput,
    FurnishedObjects,
    Navbar
  }
})
export default class Calculation extends Vue {
  /**
   * This component uses a local copy of the state that will be
   * committed to store with this.calculate()
   * */
  private localCalc: CalculationState = store.calc;
  public initialFurnishingOption: boolean =
    this.furniture.length > 0 ? true : false; // Initial value for the toggle button

  @Prop({ default: null })
  private jumpToSection!: number | null;
  private inputValue: number = 1;

  /** Holds the index of the v-expansion-panel that is currently opened */
  private expandedSection: number | null = 2;
  private focusRoomName: boolean = false;

  // Declare mounted lifecycle hook
  async mounted() {
    if (this.jumpToSection !== null) {
      this.expandedSection = this.jumpToSection;
      const scrollTarget =
        document.querySelectorAll(".v-expansion-panel")[this.jumpToSection];
      setTimeout(() => {
        // wait until panel is opened and scroll into view:
        scrollTarget.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  }
  private validationError: string = "";

  /** Defines which wall is selected and if areaEditor is opened */
  private wallEditorState: WallEditorState = {
    activeWall: 0,
    showAreaEditor: false
  };

  get furnitureObjects() {
    return this.furniture;
  }

  get dinScenario() {
    return this.localCalc.dinScenario;
  }

  set dinScenario(dinScenario) {
    this.localCalc = { ...this.localCalc, dinScenario }; // immutable
  }

  get roomSize() {
    return this.localCalc.roomSize;
  }

  set roomSize(roomSize) {
    this.localCalc = { ...this.localCalc, roomSize }; // immutable
  }

  get roomWalls() {
    return this.localCalc.roomWalls;
  }

  set roomWalls(roomWalls) {
    this.localCalc = { ...this.localCalc, roomWalls }; // immutable
  }

  get atmosphere() {
    return this.localCalc.atmosphere;
  }

  set atmosphere(atmosphere) {
    this.localCalc = { ...this.localCalc, atmosphere }; // immutable
  }

  get furniture() {
    return this.localCalc.furniture;
  }

  set furniture(furniture) {
    this.localCalc = { ...this.localCalc, furniture }; // immutable
  }

  saveCalc() {
    return store.setCalc(this.localCalc);
  }

  get FREQUENCIES() {
    return FREQUENCIES;
  }
  get FREQUENCY_SYMBOLS() {
    return freqSymbols;
  }

  get dimensionalAbsorption(): TriDimensional<OctoFrequential<number>> {
    return store.dimensionalAbsorption;
  }
  get dimensionalScattering(): TriDimensional<OctoFrequential<number>> {
    return store.dimensionalScattering;
  }

  selectWallSlot(wIdx: number) {
    if (wIdx === this.wallEditorState.activeWall) {
      return;
    }
    this.wallEditorState.activeWall = wIdx as WallEditorState["activeWall"];
    this.wallEditorState.showAreaEditor = false;
  }

  collapseAllInputPanels() {
    this.expandedSection = null;
  }

  projectNotInDatabase(): boolean {
    return !PROJECT_DATABASE.has(store.roomUUID);
  }

  async duplicateProject() {
    const localProject: Project = {
      uuid: store.roomUUID,
      title: this.roomName,
      description: this.roomDescription,
      projectData: this.localCalc
    };
    const duplicated = await SharedFunctions.duplicateProject(localProject);
    if (duplicated) {
      this.roomName = store.roomName;
      this.roomDescription = store.roomDescription;
      this.$forceUpdate();
    }
  }

  async saveAndSimulate() {
    store.roomName = this.roomName;
    if (
      !store.roomName ||
      store.roomName === "" ||
      !checkRoomNameRules(store.roomName, true)
    ) {
      ((this.$refs.roomName as any).$el as HTMLElement).scrollIntoView({
        behavior: "smooth"
      });
      this.handleFurnishingOptionClick();
      return;
    }
    store.roomDescription = this.roomDescription;
    store.setCalc(this.localCalc);
    this.removeEmptyFurnitureObjects();
    const requestBody: Project = {
      uuid: store.roomUUID,
      title: store.roomName,
      description: store.roomDescription,
      projectData: store.calc
    };
    let response;
    if (requestBody.uuid) {
      response = await ProjectDB.updateProject(requestBody.uuid, requestBody); // PUT
    } else {
      response = await ProjectDB.addProject(requestBody); // POST
      store.roomUUID = response.uuid;
    }
    store.calculate();
    store.simulatedButtonPressed = true;
    this.$router.push({ name: "Results!" });
  }

  removeEmptyFurnitureObjects() {
    store.calc.furniture = store.calc.furniture.filter(
      item => item.uuid !== ""
    );
    if (store.calc.furniture.length < 1) this.setFurnishingOption(false);
  }

  get scenarioSymbols(): Readonly<string[]> {
    return SCENARIO_SYMBOLS;
  }
  /** Returns label objects for all scenarios except `DIN_SCENARIOS.NONE` */
  get scenarioLabels(): { id: number; label: string }[] {
    return SCENARIO_IDS.filter(scenID => scenID !== DIN_SCENARIOS.NONE).map(
      scenID => ({ id: scenID, label: SCENARIO_TITLES[scenID] })
    );
  }

  // get roomName(): string {
  //   return store.roomName;
  // }
  // set roomName(name: string) {
  //   store.roomName = name;
  // }
  roomName = store.roomName;
  roomDescription = store.roomDescription;

  setFurnishingOption(selectedOption: boolean) {
    this.initialFurnishingOption = selectedOption;
    if (selectedOption) {
      this.expandedSection = 3;
      if (!this.furniture.length)
        this.furniture = [
          {
            quantity: 1,
            uuid: "",
            position: 6
          }
        ];
    }
    if (!selectedOption && this.expandedSection === 3)
      this.expandedSection = null;
  }

  /* Room name input focus-handling */
  handleFurnishingOptionClick() {
    if (!this.roomName && !this.focusRoomName) {
      this.focusRoomName = true;
    } else if (
      this.focusRoomName &&
      this.$refs.roomName !== document.activeElement
    ) {
      this.$nextTick(() => {
        (this.$refs.roomName as HTMLInputElement)?.focus();
      });
    }
  }

  created() {
    this.$watch("focusRoomName", newVal => {
      console.log(this.roomName);
      if (newVal && !this.roomName) {
        this.$nextTick(() => {
          (this.$refs.roomName as HTMLInputElement)?.focus();
        });
      }
    });
    this.handleFurnishingOptionClick();
  }

  data() {
    return {
      focusRoomName: false,
      rules: roomNameRules(true)
    };
  }
}
