






import { Component, Prop, Vue } from "vue-property-decorator";
import { AppState } from "@/state/state";
import { SCENARIO_TITLES } from "@/calc/din-requirements";

@Component({
  components: {}
})
export default class SummaryDinScenario extends Vue {
  @Prop()
  dinScenario!: AppState["calc"]["dinScenario"];
  get scenarioSummary() {
    return SCENARIO_TITLES[this.dinScenario];
  }
}
