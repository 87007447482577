import {
  ABSORPTION_DATABASE,
  Material,
  SCATTERING_DATABASE
} from "@/res/materials-absorption";
import {
  OctoFrequential,
  SixDirectional,
  SixRoomWalls,
  TriDimensional,
  octoZip
} from "./room";
import { wallsTotalArea } from "./sound-pressure-diffuse";
import { FurnitureConfiguration } from "@/state/state";
import { FURNITURE_DATABASE } from "@/res/furniture-surfaces";

/** A part of a wall that has an absorption and scattering material assigned. */
export interface AreaAssignment {
  /** Size of the area in m² or null for automatic size */
  area: number | null;
  /** Id of the assigned absorption material */
  mat: Material["uuid"];
  /** Id of the assigned scattering material */
  matSc: Material["uuid"];
}

export type WallConfiguration = AreaAssignment[];

/** Can be used for absorption or scattering assignments. */
export interface ProcessedAssignment {
  /** Assigned size of the area relative to the total area of the wall */
  weight: number;
  /** Absorption/Scattering values*/
  values: Readonly<OctoFrequential<number>>;
}
/** Can be used for absorption or scattering assignments. */
export type ProcessedWallConfiguration = ProcessedAssignment[];

/**
 * Distributes the remaining area evenly among the assignments marked as *auto-size*.
 * Returns an array that containes the size of each assignment for all assinments.
 * */
export function autoDivideRemainingArea(
  assignments: WallConfiguration,
  totalArea: number
): number[] {
  const realValues = assignments.filter(a => a.area !== null);
  const autoCount = assignments.length - realValues.length;
  const assignedArea = realValues.reduce<number>(
    (tot, curr) => tot + (curr.area as number),
    0
  );
  const chunkSize = (totalArea - assignedArea) / autoCount;

  return assignments.map(a => (a.area === null ? chunkSize : a.area));
}

export function processedAbsorptionAssignments(
  assignments: WallConfiguration,
  totalArea: number
): ProcessedWallConfiguration {
  const realAreaSizes = autoDivideRemainingArea(assignments, totalArea);
  return assignments.map((a, aIdx) => {
    const mat =
      ABSORPTION_DATABASE.get(a.mat)?.values ||
      ([0, 0, 0, 0, 0, 0, 0, 0] as const); // Todo: Don't fail silently!
    return {
      weight: realAreaSizes[aIdx] / totalArea,
      values: mat
    };
  });
}
export function processedScatteringAssignments(
  assignments: WallConfiguration,
  totalArea: number
): ProcessedWallConfiguration {
  const realAreaSizes = autoDivideRemainingArea(assignments, totalArea);
  return assignments.map((a, aIdx) => {
    const matSc =
      SCATTERING_DATABASE.get(a.matSc)?.values ||
      ([0, 0, 0, 0, 0, 0, 0, 0] as const); // Todo: Don't fail silently!
    return {
      weight: realAreaSizes[aIdx] / totalArea,
      values: matSc
    };
  });
}

export function wallAverage(
  processedAssignments: ProcessedWallConfiguration
): OctoFrequential<number> {
  let alpha_i: OctoFrequential<number> = [0, 0, 0, 0, 0, 0, 0, 0];
  processedAssignments.forEach(pa =>
    pa.values.forEach((fVal, fIdx) => (alpha_i[fIdx] += fVal * pa.weight))
  );
  return alpha_i;
}

export function dimensionalAverage(
  processedWall0: ProcessedWallConfiguration,
  processedWall1: ProcessedWallConfiguration
): OctoFrequential<number> {
  return octoZip(
    wallAverage(processedWall0),
    wallAverage(processedWall1),
    (a, b) => (a + b) / 2
  );
}

export function dimensionalAverageAbsorption(
  roomWalls: SixRoomWalls,
  roomSize: TriDimensional<number>,
  furniture: FurnitureConfiguration[]
): TriDimensional<OctoFrequential<number>> {
  const wallArea = wallsTotalArea(roomSize);
  const processedWalls = roomWalls.map((w, wIdx) =>
    processedAbsorptionAssignments(w, wallArea[wIdx])
  );
  const totalSurface = eval(wallArea.join("+"));
  const directionalSurface = getDirectionalSurface(wallArea);
  return [
    addFurnitureAlpha(
      "x",
      totalSurface,
      directionalSurface[0],
      dimensionalAverage(processedWalls[0], processedWalls[1]),
      furniture
    ),
    addFurnitureAlpha(
      "y",
      totalSurface,
      directionalSurface[1],
      dimensionalAverage(processedWalls[2], processedWalls[3]),
      furniture
    ),
    addFurnitureAlpha(
      "z",
      totalSurface,
      directionalSurface[2],
      dimensionalAverage(processedWalls[4], processedWalls[5]),
      furniture
    )
  ];
}
function addFurnitureAlpha(
  direction: keyof Ao_i_m,
  S: number,
  S_i: number,
  alpha_i: OctoFrequential<number>,
  furniture: FurnitureConfiguration[]
) {
  // Get dir

  const directionalFurnitureSurface = getDirectionalFurnitureSurface(furniture);
  const sum_Ao_k = directionalFurnitureSurface.none;
  const sum_Ao_i_m = directionalFurnitureSurface[direction];
  const alpha_i2 = alpha_i.map((a_i, idx) => {
    let alpha_i_m =
      (a_i * S_i + sum_Ao_k[idx] * (S_i / S) + sum_Ao_i_m[idx]) / S_i;
    if (alpha_i_m > 0.99) alpha_i_m = 0.99;
    // (i=x,y,z;α_(i,m)≤1)
    return alpha_i_m;
  });
  return alpha_i2 as OctoFrequential<number>;
}
interface Ao_i_m {
  x: number[];
  y: number[];
  z: number[];
  none: number[];
  xyz: number[];
}
const areaLookup: { [key: number]: keyof Ao_i_m } = {
  0: "x",
  1: "x",
  2: "y",
  3: "y",
  4: "z",
  5: "z",
  6: "none"
};

function getDirectionalFurnitureSurface(
  furniture: FurnitureConfiguration[]
): Ao_i_m {
  const newAo_i_m: Ao_i_m = {
    x: [0, 0, 0, 0, 0, 0, 0, 0],
    y: [0, 0, 0, 0, 0, 0, 0, 0],
    z: [0, 0, 0, 0, 0, 0, 0, 0],
    none: [0, 0, 0, 0, 0, 0, 0, 0],
    xyz: [0, 0, 0, 0, 0, 0, 0, 0]
  };
  if (!furniture) {
    console.error("Add furniture reference to SummaryAbsorptionScattering.vue");
    return newAo_i_m;
  }
  furniture.forEach(furniture => {
    const direction = areaLookup[furniture.position];
    const furnitureAreas = FURNITURE_DATABASE.get(furniture.uuid)?.values || [
      0, 0, 0, 0, 0, 0, 0, 0
    ];
    furnitureAreas.forEach((area, idx) => {
      const objectArea = furniture.quantity * area;
      newAo_i_m[direction][idx] += objectArea;
      if (direction === "x" || direction === "y" || direction === "z")
        newAo_i_m.xyz[idx] += objectArea;
    });
  });
  return newAo_i_m;
}

// export function get_Ao(
//   furniture: FurnitureConfiguration[]
// ): OctoFrequential<number> {
//   const Ao: OctoFrequential<number> = [0, 0, 0, 0, 0, 0, 0, 0];
//   if (!furniture) {
//     console.error("Add furniture reference for area-assignment");
//     return [0, 0, 0, 0, 0, 0, 0, 0];
//   }
//   furniture.forEach(furniture => {
//     const furnitureAreas = FURNITURE_DATABASE.get(furniture.id)!.values;
//     furnitureAreas.forEach((area, idx) => {
//       const objectArea = furniture.quantity * area;
//       Ao[idx] += objectArea;
//     });
//   });
//   return Ao;
// }

function getDirectionalSurface(wallArea: SixDirectional<number>): number[] {
  const directionalSurface = [
    wallArea[0] + wallArea[1],
    wallArea[2] + wallArea[3],
    wallArea[4] + wallArea[5]
  ];
  return directionalSurface as number[];
}

export function dimensionalAverageScattering(
  roomWalls: SixRoomWalls,
  roomSize: TriDimensional<number>
): TriDimensional<OctoFrequential<number>> {
  const wallArea = wallsTotalArea(roomSize);
  const processedWalls = roomWalls.map((w, wIdx) =>
    processedScatteringAssignments(w, wallArea[wIdx])
  );
  return [
    dimensionalAverage(processedWalls[0], processedWalls[1]),
    dimensionalAverage(processedWalls[2], processedWalls[3]),
    dimensionalAverage(processedWalls[4], processedWalls[5])
  ];
}

export function generateDefaultAreaAssignments(): WallConfiguration {
  return [
    {
      area: null,
      mat: "e2e2a8a7-4278-4c8f-92d8-302bc54f1bca",
      matSc: "5fa04f6d-81e8-4905-91e1-64a3a4779e8d"
    }
  ];
}
export function generateInterestingRoom(): SixRoomWalls {
  return [
    generateDefaultAreaAssignments(),
    generateDefaultAreaAssignments(),
    generateDefaultAreaAssignments(),
    generateDefaultAreaAssignments(),
    generateDefaultAreaAssignments(),
    generateDefaultAreaAssignments()
  ];
}
export function generateFurniture(): FurnitureConfiguration[] {
  return [];
}
