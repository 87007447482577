import { Material } from "@/res/materials-absorption";

/**
 * Generates a black-and-white striped fill pattern from the frequency values of an absorption material.
 * The y-Value of the stripe from left to right corresponds to the absorption values from low frequencies to high frequencies.
 * The thickness of the black stripes is derived from the average absorption over all frequencies.
 * A full absorption of 1 at all frequencies leads to a completely black fill with no remaining white stripes in between.
 * A minimal absorption of 0 at all frequencies leads to a completely white fill as the black stripes have zero thickness.
 * @param mat Material to generate the fill pattern from
 * */
export function materialStripePattern(
  absorptionValues: Material["values"]
): Partial<CSSStyleDeclaration> {
  const points = absorptionValues.map((v, idx) => [idx, 10 - v * 10]);
  const level = absorptionValues.reduce((a, b) => a + b, 0) / 0.8;
  const polygon = [
    ...points,
    ...points.reverse().map(p => [p[0], level + p[1]])
  ];
  const polyString = polygon.map(p => p.join(",")).join(" ");
  return {
    backgroundImage: `url("data:image/svg+xml;utf8,\
        <svg viewBox='0 0 7 10' preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg'>\
          <polygon id='profile' points='${polyString}'/>\
          <use x='0' y='-10' href='%23profile' />\
        </svg>")`
  };
}

/**
 * Generates jagged-line fill pattern from the frequency values of an absorption material.
 * High scattering material leads to jagged line while for a low scattering material the line will be straight.
 * Currently only the 1kHz value influences the pattern.
 * @param mat Material to generate the fill pattern from
 */
export function scatteringPattern(
  scatteringValues: Material["values"]
): Partial<CSSStyleDeclaration> {
  const maxVal = 0.17;
  const minVal = 0.05;
  const weighting = [0, 0, 0, 0, 1, 0, 0, 0]; // how each frequency will influence the pattern
  // Currently only the 1kHz value influences the pattern:
  let strength = scatteringValues.reduce(
    (sum, fVal, fIdx) => sum + fVal * weighting[fIdx],
    0
  );
  // normalize:
  strength -= minVal;
  strength /= maxVal - minVal;
  // Clamp to range [0,1]:
  strength = Math.max(Math.min(strength, 1), 0);
  const points: string = [
    [-5, 0],
    [5, 5],
    [-5, 10]
  ]
    .map(p => [p[0] * strength, p[1]].join(","))
    .join(" ");

  return {
    backgroundImage: `url("data:image/svg+xml;utf8,\
        <svg viewBox='-5 0 10 10' preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg'>\
            <polyline id='profile' points='${points}' fill='none' stroke='black' stroke-width='1'/>\
        </svg>")`
  };
}
