





























import { EventBus, SharedFunctions } from "@/state/state";
import { Component, Vue } from "vue-property-decorator";

@Component({ components: {} })
export default class PromptConfirmProject extends Vue {
  async created() {
    SharedFunctions.promptConfirmation = this.promptConfirmation;
    EventBus.$on("prompt-confirmation", this.promptConfirmation);
  }
  private confirmationOpen = false;
  private confirmationText = "";
  async promptConfirmation(message: string) {
    return new Promise(resolve => {
      this.confirmationText = message;
      this.confirmationOpen = true;

      const closeDialog = (response: any) => {
        this.confirmationOpen = false;
        resolve(response);
      };
      this.$once("confirmed", closeDialog);
    });
  }
}
