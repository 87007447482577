




















import { EventBus } from "@/state/state";
import { Component, Vue } from "vue-property-decorator";

@Component({ components: {} })
export default class PromptError extends Vue {
  async created() {
    EventBus.$on("prompt-error", this.promptError);
  }
  dialogOpen = false;
  dialogText = "";
  promptError(text: string) {
    this.dialogText = text;
    this.dialogOpen = true;
  }
}
