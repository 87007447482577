import { soundPressureLevel } from "./decibel";
import { FrequentialResult } from "./sound-pressure-summed";
export interface DecayCurve {
  /** SPL samples of the curve in dB */
  datapoints: Float64Array;
  /** Timestep between two samples */
  deltaT: number;
}

/** Decay Curve as an arrow function with parameter t in seconds*/
export type DecayFunction = (t: number) => number;

/**
 * Renders a DecayCurve with the specified amount of
 * datapoints at specified time steps.
 * @param decayFunction arrow function to render the curve from
 * @param deltaT time step between two datapoints
 * @param iterations number of datapoints to render
 * */
export function renderDecayCurve(
  decayFunction: DecayFunction,
  deltaT: number,
  iterations: number
): DecayCurve {
  const curve: DecayCurve = {
    deltaT,
    datapoints: new Float64Array(iterations + 1)
  };
  for (let it = 0; it <= iterations; it++) {
    const time = deltaT * it;
    // Squared sound pressure of the diffuse field at t:
    curve.datapoints[it] = decayFunction(time);
  }
  return curve;
}

/**
 * Takes a decayFunction in squared sound pressure and returns a new decayFunction that returns values in SPL dB.
 * @param decayFunctionP2 squared soundpressure decayFunction in P²
 * */
export function decayFunctionToDB(
  decayFunctionP2: DecayFunction
): DecayFunction {
  return time => soundPressureLevel(decayFunctionP2(time));
}

/**
 * Aproximates an intersection of the given decayFunction
 * with the given level in the given interval using the Bisect algorithm.
 * */
export function findIntersection(
  decayFunction: DecayFunction,
  intervalStart: number,
  intervalEnd: number,
  level: number
): { time: number; iterations: number } {
  const precision = 1e-16;
  let error = Infinity;
  let iterations = 0;
  let c = 0;
  while (Math.abs(error) > precision && iterations < 2000) {
    c = (intervalStart + intervalEnd) / 2;
    error = decayFunction(c) - level;
    if (error > 0) {
      intervalStart = c;
    } else if (error < 0) {
      intervalEnd = c;
    }
    iterations++;
  }
  return { time: c, iterations };
}

/** Calculates the decay time using C.Sabine including air */
export function reverberationTimeSabine(
  S: number,
  V: number,
  m_f: number,
  alpha_s: number,
  c_0: number
) {
  const A = alpha_s * S + 4 * m_f * V;
  const factor = (24 * Math.log(10)) / c_0;
  const RT60 = factor * (V / A);
  // Calculate RT30:
  return RT60;
}

/**
 * Determines the Reverberation time Zhou et al. 2021 by doing an 30dB intersection with the decay function.
 * @param SPL_dB_relative The Zhou decay function in relative dB (starts with 0dB)
 * @returns reverberation Time RT30
 */
export function reverberationTimeZhou(
  SPL_dB_relative: FrequentialResult["SPL_dB_relative"]
) {
  return findIntersection(SPL_dB_relative, 0, 100, -30).time * 2;
}
