import {
  Endpoint,
  HttpMethod,
  RequestParams,
  performCRUDRequest
} from "@/services/userServices";
import { CreateMaterialRequest } from "./materials-absorption";

export interface Furniture {
  /** Should be a unique 28-bit sequence that never changes. Will not be visible to user. */
  uuid: string;
  /** Short unique furniture title that will be shown in the user interface. */
  title: string;
  /** Values for the eight frequency bands 62.5, 125, 25 500, 1000, 2000, 4000, 8000 Hz */
  values: readonly [
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number
  ];
  /** Furniture description that will be shown in the furniture details. */
  description: string;
  /** Scientific reference/Source of the furniture data. */
  source: string;
  /** Should be a short unique number that never changes. Will not be visible to the user. */
  type: number;
}
export type FurnitureDatabase = Map<Furniture["uuid"], Furniture>;

export let FURNITURE_OBJECTS: Furniture[] = [];
export let FURNITURE_DATABASE: FurnitureDatabase;

export function initFurniture(furnitureData: Furniture[]) {
  FURNITURE_OBJECTS = furnitureData;
  FURNITURE_DATABASE = new Map(FURNITURE_OBJECTS.map(m => [m.uuid, m]));
}

export var FurnitureDB = {
  //Post
  async addFurniture(newFurniture: CreateMaterialRequest): Promise<any> {
    try {
      const params: RequestParams = {
        method: HttpMethod.POST,
        endpoint: Endpoint.Furniture,
        UUID: undefined,
        data: newFurniture
      };
      const response: Furniture = await performCRUDRequest<Furniture>(params);
      this.add_FURNITURE_OBJECT(response);
      return response;
    } catch (error) {
      console.error("Error saving new furniture", error);
      throw error;
    }
  },
  add_FURNITURE_OBJECT(newFurniture: Furniture) {
    FURNITURE_OBJECTS.push(newFurniture);
    FURNITURE_DATABASE.set(newFurniture.uuid, newFurniture);
  },

  /** PUT */
  async updateFurniture(updateFrnt: any, uuid: any): Promise<any> {
    try {
      const params: RequestParams = {
        method: HttpMethod.PUT,
        endpoint: Endpoint.Furniture,
        UUID: uuid,
        data: updateFrnt
      };
      const response = await performCRUDRequest<Furniture>(params);
      FURNITURE_DATABASE.set(uuid, updateFrnt);
      return response;
    } catch (error) {
      console.error("Error updating furniture", error);
      throw error;
    }
  },

  /** get the UUID of the previous furniture. Used to select previous furniture when one is deleted */
  getPreviousUUID: (currentUUID: string): string | null => {
    let previousUUID: string | null = null;
    for (const [uuid] of FURNITURE_DATABASE) {
      if (uuid === currentUUID) {
        break;
      }
      previousUUID = uuid;
    }
    return previousUUID;
  },

  /** DELETE */
  async deleteFurnitureByUUID(UUID: string): Promise<any> {
    try {
      const params: RequestParams = {
        method: HttpMethod.DELETE,
        endpoint: Endpoint.Furniture,
        UUID: UUID,
        data: undefined
      };
      const response: Furniture = await performCRUDRequest<Furniture>(params);
      this.remove_FURNITURE_OBJECT(response.uuid);
      return response;
    } catch (error) {
      console.error("Error removing furniture", error);
      throw error;
    }
  },
  remove_FURNITURE_OBJECT(UUID: string) {
    FURNITURE_DATABASE.delete(UUID);
    FURNITURE_OBJECTS = Array.from(FURNITURE_DATABASE.values());
  }
};
