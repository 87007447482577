

















import { Component, Prop, Vue } from "vue-property-decorator";
import { AppState } from "@/state/state";
import AbsorptionScatteringThumbnail from "./AbsorptionScatteringThumbnail.vue";
import {
  dimensionalAverageAbsorption,
  dimensionalAverageScattering
} from "@/calc/area-assignment";
import { TriDimensional, OctoFrequential } from "@/calc/room";

@Component({
  components: { AbsorptionScatteringThumbnail }
})
export default class SummaryRoomDimensions extends Vue {
  @Prop()
  roomSize!: AppState["calc"]["roomSize"];

  @Prop()
  roomWalls!: AppState["calc"]["roomWalls"];

  @Prop()
  furniture!: any[];

  get dimensionalAbsorption(): TriDimensional<OctoFrequential<number>> {
    return dimensionalAverageAbsorption(
      this.roomWalls,
      this.roomSize,
      this.furniture
    );
  }
  get dimensionalScattering(): TriDimensional<OctoFrequential<number>> {
    return dimensionalAverageScattering(this.roomWalls, this.roomSize);
  }

  get summary() {
    return ["X", "Y", "Z"].map((dim, dIdx) => ({
      label: `${dim}:\u202f`,
      alpha: this.dimensionalAbsorption[dIdx],
      sc: this.dimensionalScattering[dIdx]
    }));
  }
}
