/**
 * Effective frontal absorption.
 * From (Zhou et al., 2021, Eq. 10)
 * @param alpha_i average absorption coefficient in the regarded direction
 * @param m_f absorption of air
 * @param L_i one free path length in the regarded direction
 * @param BR_ie effective lateral sound absorption in the regarded direction
 */
export function effectiveAbsorption(
  alpha_i: number,
  m_f: number,
  L_i: number,
  BR_ie: number
) {
  return alpha_i * (Math.exp(-m_f * L_i) - BR_ie);
}
