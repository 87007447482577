import { WallConfiguration } from "./area-assignment";

/** Tuple with 3 elements of type T */
export type TriDimensional<T> = [T, T, T];

/** Tuple with 8 elements of type T */
export type OctoFrequential<T> = [T, T, T, T, T, T, T, T];

/**
 * Tuple with 6 elements of type T that represent the directions
 * [x0, x1, y0, y1, z0, z1]
 * */
export type SixDirectional<T> = [T, T, T, T, T, T];

/**
 * Wall configurations for the walls
 * [x0, x1, y0, y1, z0, z1]
 * */
export type SixRoomWalls = SixDirectional<WallConfiguration>;

export function getDim(wallIdx: 0 | 1 | 2 | 3 | 4 | 5): 0 | 1 | 2 {
  return Math.floor(wallIdx / 2) as 0 | 1 | 2;
}

export function getDir(wallIdx: 0 | 1 | 2 | 3 | 4 | 5): 0 | 1 {
  return (wallIdx % 2) as 0 | 1;
}

/**
 * Type helper that fixes the array.map() function to preserve tuple length.
 * See https://stackoverflow.com/questions/57913193/how-to-use-array-map-with-tuples-in-typescript
 * */
export interface OctoMappable<T> extends Readonly<OctoFrequential<T>> {
  map<U>(
    mapFunction: (el: T, fIdx: number, array: T[]) => U
  ): OctoFrequential<U>;
}

/**
 * Combines two octo-frequential sets with the given operation.
 * The operation will be executed for each element of set a with the corresponding element of set b.
 * A new octo-frequential of the same type will be returned.
 * */
export function octoZip<T, U, V>(
  a: OctoFrequential<T>,
  b: OctoFrequential<U>,
  combineFunction: (a: T, b: U) => V
): OctoFrequential<V> {
  return [
    combineFunction(a[0], b[0]),
    combineFunction(a[1], b[1]),
    combineFunction(a[2], b[2]),
    combineFunction(a[3], b[3]),
    combineFunction(a[4], b[4]),
    combineFunction(a[5], b[5]),
    combineFunction(a[6], b[6]),
    combineFunction(a[7], b[7])
  ];
}

/**
 * Type helper that fixes the array.map() function to preserve tuple length.
 * See https://stackoverflow.com/questions/57913193/how-to-use-array-map-with-tuples-in-typescript
 * */
export interface TriMappable<T> extends Readonly<TriDimensional<T>> {
  map<U>(
    mapFunction: (el: T, dIdx: number, array: T[]) => U
  ): TriDimensional<U>;
}

/** Holds the complete configuration of a room for the dimensions x, y and z */
export type RoomSingleFreq = TriDimensional<RoomDirectionSingleFreq>;

/** Holds the multi-frequency configuration of one direction of a room */
export interface RoomDirectionMultiFreq {
  /** Length of the room in this direction */
  L: number;
  /** Scattering of the room in this direction */
  sc: OctoFrequential<number>;
  /** Absorption of the room in this direction */
  alpha: OctoFrequential<number>;
}

/** Holds the configuration of one direction of a room */
export interface RoomDirectionSingleFreq {
  /** Length of the room in this direction */
  L: number;
  /** Scattering of the room in this direction */
  sc: number;
  /** Absorption of the room in this direction */
  alpha: number;
}
export interface Perspective {
  frontal: RoomDirectionSingleFreq;
  lateralA: RoomDirectionSingleFreq;
  lateralB: RoomDirectionSingleFreq;
}

export enum DIMENSION {
  x = 0,
  y = 1,
  z = 2
}

export const DIMENSIONS: Readonly<TriDimensional<string>> = [
  "x",
  "y",
  "z"
] as const;

export function getPerspective(room: RoomSingleFreq, direction: DIMENSION) {
  return {
    frontal: room[direction],
    lateralA: room[(direction + 1) % 3],
    lateralB: room[(direction + 2) % 3]
  };
}

export const WALL_TITLES: Readonly<SixDirectional<string>> = [
  "Back Wall",
  "Front Wall",
  "Left Wall",
  "Right Wall",
  "Floor",
  "Ceiling"
] as const;

export const WALL_SYMBOLS: Readonly<SixDirectional<string>> = [
  "X0",
  "X1",
  "Y0",
  "Y1",
  "Z0",
  "Z1"
] as const;
