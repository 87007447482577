
























































import { Component, Vue, Prop } from "vue-property-decorator";
import * as AUTH from "@/state/login";

@Component({
  components: {}
})
export default class Navbar extends Vue {
  @Prop({ default: "primary", type: String }) readonly appBarColor!: string;

  // logs out the user when logout button is pressed
  logout() {
    AUTH.logout();
  }

  // redirecting the User to the desired page, but only if the destination page is not the same as actual page
  redirect(page: string) {
    if (this.$route.name !== page) {
      this.$router.push({ name: page });
    }
  }

  // checking the current page/view to control if the buttons are abled or disabled
  currentView() {
    return this.$route.name;
  }
}
