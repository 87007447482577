import { OctoFrequential } from "./room";

/** 0°C in K */
const CELSIUS_0 = 273.15;

/**
 * Converts an absolute temperature value from °C to Kelvin.
 * @param tempCelsius Absolute temperature value in °C
 */
export function kelvin(tempCelsius: number) {
  return tempCelsius + CELSIUS_0;
}

/** Reference air temperature, in K */
export const T0 = kelvin(20);

/**
 * Reference ambient atmospheric pressure, in Pa
 * (International Standard Atmosphere at mean sea level)
 * ISO 9613-1:1993(E) S.2
 */
export const pr = 101325;

/**
 * Speed of sound at given temperature in dry air, in m/s
 * @param temperature Ambient atmospheric temperature, in K
 */
export function soundSpeed(temperature: number): number {
  const c_0 = 331.5 * Math.sqrt(temperature / CELSIUS_0); // Todo: should this take humidity into account?
  return c_0;
}

/**
 * Density of dry air at the given temperature, in kg/m³
 * @param temperature Ambient atmospheric temperature, in K
 */
export function densityOfDryAir(temperature: number): number {
  /** The specific gas constant for dry air, in J/(kg·K) */
  const R_specific = 287.058;
  const rho_0 = pr / (R_specific * temperature);
  return rho_0;
}

/** Soundpower of the source, in Watt */
export const W_0 = 0.01;

/** Reference sound pressure, in Pa */
export const p_ref = 2e-5;

/**
 * Squared reference sound pressure, in Pa^2
 * (Stored as constant for performance reasons)
 */
export const p_refSquared = p_ref * p_ref;

/** Available sound frequency octave bandys to choose from */
export const FREQUENCIES: Readonly<OctoFrequential<number>> = [
  62.5, 125, 250, 500, 1000, 2000, 4000, 8000
] as const;

export const freqSymbols: Readonly<OctoFrequential<string>> = [
  "62.5",
  "125",
  "250",
  "500",
  "1k",
  "2k",
  "4k",
  "8k"
] as const;

/** Allowed sound frequency octave band for calculation */
export type FREQUENCY = typeof FREQUENCIES[number];

export type FrequencyIndex = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;

/** Atmospheric constants of a room */
export interface Atmosphere {
  /** Ambient atmospheric temperature, in K */
  T: number;
  /** Ambient atmospheric pressure, in Pa */
  pa: number;
  /** Relative humidity, in % */
  hum_rel: number;
}

/**
 * Molar concentration of water vapour, as a percentage.
 * (Parameter h from ISO 9613-1, Annex B, B.1).
 * @param atmosphere.hum_rel relative humidity of air, in %
 * @param atmosphere.pa ambient atmospheric pressure, in Pa
 * @param atmosphere.T ambient atmospheric temerature, in kelvin
 */
export function molarVapourConcentration(atmospere: Atmosphere): number {
  const { hum_rel, pa, T } = atmospere;

  /** Triple point of water (Vienna Standard Mean Ocean Water), in K */
  const T_triple = kelvin(0.01);

  /**
   * Molar concentration of water vapour. By Avogadro’s law, this is also the
   * ratio of the partial pressure of water vapour to the atmospheric pressure.
   */
  const P_sat_over_pr = 10 ** (-6.8346 * (T_triple / T) ** 1.261 + 4.6151);
  const p_rel = pa / pr;

  /** As a percentage (h from ISO 9613-1, Annex B, B.1) */
  const h = hum_rel * (P_sat_over_pr / p_rel);
  return h;
}

/**
 * Calculates the sound attenuation coefficient of air in dB SPL/m
 * (from ISO 9613-1: 1993 Acoustics-Attenuation of sound during propagation outdoors)
 * @param f Frequency of sound, in Hz
 * @param atmosphere.pa Ambient atmospheric pressure, in Pa
 * @param atmosphere.T Ambient atmospheric temerature, in kelvin
 * @param atmosphere.hum_rel Relative humidity, in %
 */
export function attenuationOfAir(atmosphere: Atmosphere, f: number) {
  const { hum_rel, pa, T } = atmosphere;

  /** Molar concentration of water vapour, as a percentage  */
  const h = molarVapourConcentration({ hum_rel, pa, T });

  const p_rel = pa / pr;
  const T_rel = T / T0;
  const f_square = f * f;

  /** Relaxation frequency of oxygen, in Hz */
  const frO = p_rel * (24 + 4.04e4 * h * ((0.02 + h) / (0.391 + h)));

  /** Relaxation frequency of nitrogen, in Hz */
  const frN =
    (p_rel / Math.sqrt(T_rel)) *
    (9 + 280 * h * Math.exp(-4.17 * (T_rel ** (-1 / 3) - 1)));

  /** Attenuation of air, in dB SPL/m */
  const a_air =
    20 *
    Math.LOG10E *
    f_square *
    ((1.84e-11 / p_rel) * Math.sqrt(T_rel) +
      T_rel ** (-5 / 2) *
        ((0.01275 * Math.exp(-2239.1 / T)) / (frO + f_square / frO) +
          (0.1068 * Math.exp(-3352.0 / T)) / (frN + f_square / frN)));
  return a_air;
}

/**
 * Returns the dissipation factor "m" for the given attenuation of air, in m^-1.
 * @param a_air Attenuation of air in dB SPL/m
 */
export function dissipationOfAir(a_air: number): number {
  const m = a_air / (10 * Math.LOG10E);
  return m;
}
