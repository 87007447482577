






























































import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class SVGRTIndicator extends Vue {
  @Prop({ type: Number })
  reverberationTime!: number;

  @Prop({ type: Number, default: 60 })
  rTReference!: number;

  @Prop({ type: Function })
  projectX!: (x: number) => number;

  @Prop({ type: Function })
  projectY!: (y: number) => number;

  @Prop({ type: Number })
  xMax!: number;

  get point(): [number, number] {
    return [
      this.reverberationTime * (this.rTReference / 60),
      -this.rTReference
    ];
  }
}
