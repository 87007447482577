



























import { formatMathHTML } from "@/helper/math-format";
import { Component, Prop, VModel, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class ValueInput extends Vue {
  @VModel({ type: Number })
  inputValue!: number;

  @Prop()
  unit!: string;

  @Prop({ type: String, default: null, required: false })
  symbol!: string;

  @Prop({ type: String, default: null })
  title!: string | null;

  @Prop({ type: Array, default: null, required: false })
  options!: number[] | null;

  @Prop({ type: String, default: null, required: false })
  icon!: string | null;

  @Prop({ type: Number })
  max!: number;

  @Prop({ type: Number })
  min!: number;

  get symbolHTML() {
    return this.title || formatMathHTML(this.symbol);
  }
  get unitHTML() {
    return this.unit ? formatMathHTML(this.unit) : undefined;
  }

  get optionsKeyValue() {
    return this.options?.map((f, fIdx) => ({ text: f, value: fIdx }));
  }

  badInput() {
    return this.inputValue > this.max || this.inputValue < this.min;
  }

  handleInput() {
    if (this.inputValue > this.max) {
      this.inputValue = this.max;
    }
    if (this.inputValue < this.min) {
      this.inputValue = this.min;
    }
  }

  handleWheel(event: WheelEvent) {
    event.preventDefault();
    const step = Math.sign(event.deltaY);
    const newValue = this.inputValue + step;

    this.inputValue = Math.min(Math.max(newValue, this.min), this.max);
  }
}
