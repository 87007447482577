





































































import { Component, Vue } from "vue-property-decorator";
//import { EventBus } from "./state/state";
import * as AUTH from "@/state/login";
import PromptDuplicateProject from "@/components/PromptDuplicateProject.vue";
import PromptConfirmProject from "@/components/PromptConfirmProject.vue";
import PromptError from "@/components/PromptError.vue";

@Component({
  components: { PromptDuplicateProject, PromptConfirmProject, PromptError }
})
export default class App extends Vue {
  timer = "";

  async created() {
    //AppLink.view = this;

    // SharedFunctions.promptDuplicateProject = this.promptDuplicateProject;
    // EventBus.$on("prompt-error", this.promptError);

    await AUTH.establishSession();
    setInterval(this.updateTimer, 1000);
  }
  updateTimer() {
    const exp_in = AUTH.getRemainingTime();
    this.timer = exp_in > 0 ? this.secondsToHms(exp_in) : "";
  }

  secondsToHms(seconds: number) {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor((seconds % 3600) % 60);
    return `${this.dd(h)}:${this.dd(m)}:${this.dd(s)}`;
  }

  dd(num: number) {
    return num < 10 ? "0" + num : num;
  }

  // dialogOpen = false;
  // dialogText = "";
  // promptError(text: string) {
  //   this.dialogText = text;
  //   this.dialogOpen = true;
  // }

  logout() {
    AUTH.logout();
  }
}
