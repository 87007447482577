import {
  Endpoint,
  HttpMethod,
  RequestParams,
  performCRUDRequest
} from "@/services/userServices";

import {
  Material,
  initMaterials,
  initScattering
} from "./materials-absorption";
import { Furniture, initFurniture } from "./furniture-surfaces";
import { Project, initProjects } from "./project";

interface UserData {
  /** see SwaggerUI endpoint GET /user-data */
  projects: Project[];
  materialData: Material[];
  scatteringData: Material[];
  furnitureData: Furniture[];
}

export async function getAllUserData() {
  const params: RequestParams = {
    method: HttpMethod.GET,
    endpoint: Endpoint.UserData,
    UUID: undefined,
    data: undefined
  };
  return new Promise<void>((resolve, reject) => {
    performCRUDRequest<UserData>(params)
      .then((data: UserData) => {
        initMaterials(data.materialData);
        initScattering(data.scatteringData);
        initFurniture(data.furnitureData);
        initProjects(data.projects);
        resolve(); // Resolve the Promise when all operations are completed
      })
      .catch(error => {
        reject(error); // Reject the Promise in case of an error
      });
  });
}

export async function clearAllUserData() {
  initMaterials([]);
  initScattering([]);
  initFurniture([]);
  initProjects([]);
}
