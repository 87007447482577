








































import { Component, Vue } from "vue-property-decorator";

import { FurnitureDB, FURNITURE_DATABASE } from "@/res/furniture-surfaces";
@Component({
  components: {}
})
export default class LocalStorageFurniture extends Vue {
  refresh() {
    this.$emit("refreshFurnitureList", null);
    this.$emit("openPackageGroup", null);
  }

  saveToDisk() {
    //if (!ABSORPTION_MATERIALS_CUSTOM *selected!!!) return;
    let data: string = JSON.stringify(Array.from(FURNITURE_DATABASE.values()));
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8, " + encodeURIComponent(data)
    );
    element.setAttribute("download", "Furniture.package");
    document.body.appendChild(element);
    element.click();
  }

  selectFile() {
    let fileInputElement = this.$refs.fileUpload as any;
    fileInputElement.click();
  }

  addFile(x: any) {
    var fileReader = new FileReader();
    fileReader.onload = this.addToDB;
    fileReader.readAsText(x.target.files[0]);
  }

  addToDB(fileReader: any) {
    let db = JSON.parse(fileReader.target.result as string);
    db.forEach((ds: any) => {
      FurnitureDB.addFurniture(ds);
    });
    this.refresh();
  }
}
