










import { Component, Prop, Vue } from "vue-property-decorator";
import {
  DrawingStyle,
  LEGEND_ICON_OFFSET,
  DRAWING_STYLES,
  BULLET_STYLES,
  bulletType,
  drawingType
} from "@/helper/diagram";

/** id that is unique per component instance */
let uid = 0;

/** Draws a octo frequency SVG line inside a MultiFreqDiagram */
@Component({
  components: {}
})
export default class MultiFreqLine extends Vue {
  /** id used to link the svg clipping object */
  pointIndicatorID: string = `point-indicator_${uid++}`; // increase counter to ensure unique ids

  @Prop()
  drawingStyle!: drawingType | null;

  @Prop()
  bullet!: bulletType | null;

  @Prop({ type: Boolean, required: false, default: false })
  dominant!: boolean;

  @Prop({ type: String })
  label!: string;

  get bulletPath(): string | null {
    if (!this.bullet) {
      return null;
    }
    const style = BULLET_STYLES[this.bullet] || BULLET_STYLES.circle;
    return style.bullet;
  }
  get bg(): DrawingStyle {
    return this.drawingStyle
      ? DRAWING_STYLES[this.drawingStyle]
      : DRAWING_STYLES.line;
  }
  get bgCSSStyle() {
    return {
      stroke: this.bg.stroked ? this.bg.color || "inherit" : "none",
      fill: this.bg.filled ? this.bg.color || "inherit" : "none",
      fillOpacity: this.bg.fillOpacity || undefined,
      strokeDasharray: this.bg.dasharray && this.bg.dasharray.join(", "),
      strokeLinecap: this.bg.linecap,
      strokeWidth: this.bg.strokeWidth
    };
  }
  get bulletCSSStyle() {
    return {
      stroke: this.dominant ? "none" : "inherit",
      strokeWidth: this.dominant ? "0px" : "1px",
      fill: this.dominant ? "inherit" : "none"
    };
  }
  get labelXOffset(): number {
    return LEGEND_ICON_OFFSET * 1.5;
  }
}
