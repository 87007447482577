import { Perspective } from "../room";

/**
 * The cremer summand is part of lateral sound absorption
 * from (Zhou et al., 2021, 12)
 */
function cremerSummand(
  L_primary: number,
  L_other: number,
  alpha_other: number,
  c_0: number,
  f: number
) {
  return (
    Math.log(1 - alpha_other) *
    (L_primary / L_other) *
    (Math.pow(c_0, 2) / Math.pow(L_other, 2)) *
    (1 / Math.pow(f, 2))
  );
}

/**
 * Lateral sound absorption BR_i, where i=x,y,z
 * from (Zhou et al., 2021, Eq. 12)
 */
export function lateralAbsorption(
  perspective: Perspective,
  c_0: number,
  f: number
): number {
  const { frontal, lateralA, lateralB } = perspective;
  const cremerSummandA = cremerSummand(
    frontal.L,
    lateralA.L,
    lateralA.alpha,
    c_0,
    f
  );
  const cremerSummandB = cremerSummand(
    frontal.L,
    lateralB.L,
    lateralB.alpha,
    c_0,
    f
  );
  return 1 - Math.exp(cremerSummandA + cremerSummandB);
}

/**
 * Effective lateral sound absorption
 * BR_ie from (Zhou et al., 2021, Eq. 9)
 * @param lateralAbsorption
 * @param m absorption of air
 * @param L length of the room in this direction
 */
export function effectiveLateralAbsorption(
  lateralAbsorption: number,
  m: number,
  L: number
): number {
  return lateralAbsorption * Math.exp(-m * L);
}
