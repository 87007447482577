



























import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class ExplanationHint extends Vue {
  @Prop({ type: String })
  title!: string;

  private expanded: boolean = false;
}
