/**
 * Effective Scattering
 * from (Zhou et al., 2021, Eq. 11)
 * @param SC_i scattering coefficient in the regarded direction
 * @param m_f absorption of air
 * @param L_i one free path length in the regarded direction
 * @param BR_ie effective lateral sound absorption in the regarded direction
 * @param alpha_ie effective average absorption coefficient in the regarded direction
 */
export function effectiveScattering(
  SC_i: number,
  m_f: number,
  L_i: number,
  BR_ie: number,
  alpha_ie: number
) {
  return SC_i * (Math.exp(-m_f * L_i) - BR_ie - alpha_ie);
}
