var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Navbar',{staticStyle:{"z-index":"1"},attrs:{"appBarColor":'primary'}}),_c('v-container',{staticClass:"left-hand",attrs:{"fluid":""}},[_c('div',{staticClass:"smallWidth"},[_c('v-text-field',{ref:"roomName",staticClass:"pt-5",attrs:{"rules":_vm.rules,"counter":"","light":"","clearable":"","type":"text","maxlength":"40","hint":"Only Letters, Numbers and Spaces. The project file will be saved under this name","label":"Enter room name","placeholder":"New Room","required":""},model:{value:(_vm.roomName),callback:function ($$v) {_vm.roomName=$$v},expression:"roomName"}}),_c('h1',{staticClass:"text-h1 my-4",attrs:{"id":"roomConfiguration"}},[_vm._v(" Edit Your Room Configuration ")]),_c('p',[_vm._v(" Specify the size, atmosphere of your room and specify absorption and scattering materials using our wall partition editor. ")]),_c('v-expansion-panels',{model:{value:(_vm.expandedSection),callback:function ($$v) {_vm.expandedSection=$$v},expression:"expandedSection"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('h2',{staticClass:"text-h2"},[_vm._v("DIN Scenario")]),(!open)?_c('SummaryDinScenario',{staticClass:"input-summary",attrs:{"dinScenario":_vm.dinScenario}}):_vm._e()]}}])}),_c('v-expansion-panel-content',[_c('p',[_vm._v(" Which requirements does your room need to fulfill? Select a DIN 18041 usage scenario or decide later. ")]),_c('v-radio-group',{model:{value:(_vm.dinScenario),callback:function ($$v) {_vm.dinScenario=$$v},expression:"dinScenario"}},_vm._l((_vm.scenarioLabels),function(scenario,sIdx){return _c('v-radio',{key:sIdx,attrs:{"label":scenario.label,"value":scenario.id}})}),1),_c('hr'),_c('v-radio-group',{model:{value:(_vm.dinScenario),callback:function ($$v) {_vm.dinScenario=$$v},expression:"dinScenario"}},[_c('v-radio',{attrs:{"label":"Continue without DIN 18041 requirements","value":0}})],1)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('h2',{staticClass:"text-h2"},[_vm._v("Room Dimensions")]),(!open)?_c('SummaryRoomDimensions',{staticClass:"input-summary",attrs:{"roomSize":_vm.roomSize}}):_vm._e()]}}])}),_c('v-expansion-panel-content',[_c('p',[_vm._v("Specify the room's width, length and height:")]),_c('RoomPreview',{staticClass:"roomPreview smallWidth",attrs:{"size":_vm.roomSize,"showLabels":"","fillAll":""}}),_vm._l((['x', 'y', 'z']),function(dim,i){return _c('ValueInput',{key:("input_L" + dim),attrs:{"symbol":("L_" + dim),"unit":'m',"icon":("mdi-axis-" + dim + "-arrow"),"value":_vm.roomSize[i],"min":2,"max":500},on:{"input":function($event){_vm.roomSize = [].concat( _vm.roomSize );
                _vm.roomSize[i] = $event;}}})})],2)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var open = ref.open;
return [_c('h2',{staticClass:"text-h2"},[_vm._v("Absorption & Scattering")]),(!open)?_c('SummaryAbsorptionScattering',{staticClass:"input-summary",attrs:{"roomSize":_vm.roomSize,"roomWalls":_vm.roomWalls,"furniture":_vm.furniture}}):_vm._e()]}}])}),_c('v-expansion-panel-content',[_c('p',[_vm._v(" The diagrams below show a summary of the acoustical properties of each wall. To configure the materials of a wall click on the pencil icon below. ")]),_c('WallEditor',{attrs:{"state":_vm.wallEditorState,"roomSize":_vm.roomSize},on:{"stateUpdate":function($event){_vm.wallEditorState = $event}},model:{value:(_vm.roomWalls),callback:function ($$v) {_vm.roomWalls=$$v},expression:"roomWalls"}})],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"expand-icon":_vm.initialFurnishingOption ? '$vuetify.icons.expand' : '',"disabled":!_vm.initialFurnishingOption}},[_c('h2',{staticClass:"text-h2"},[_vm._v("Furniture & People")]),_c('div',{staticClass:"full-width-toggle"},[_c('v-btn',{staticClass:"rectangular-radio",class:{ selected: !_vm.initialFurnishingOption },on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.setFurnishingOption(false)}}},[_vm._v(" UNFURNISHED ")]),_c('v-btn',{staticClass:"rectangular-radio",class:{ selected: _vm.initialFurnishingOption },nativeOn:{"click":function($event){$event.stopPropagation();return _vm.setFurnishingOption(true)}}},[_vm._v(" FURNISHED ")])],1)]),(_vm.initialFurnishingOption)?_c('v-expansion-panel-content',[_c('v-card-text',[_vm._v(" Select the furniture, objects and the number of people in the room to define their additional equivalant absorption area. "),_c('FurnishedObjects',{staticClass:"areaEditor",model:{value:(_vm.furniture),callback:function ($$v) {_vm.furniture=$$v},expression:"furniture"}})],1)],1):_vm._e()],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var open = ref.open;
return [_c('h2',{staticClass:"text-h2"},[_vm._v("Atmosphere")]),(!open)?_c('SummaryAtmosphere',{staticClass:"input-summary",attrs:{"atmosphere":_vm.atmosphere}}):_vm._e()]}}])}),_c('v-expansion-panel-content',[_c('v-row',[_c('v-col',[_c('ValueInput',{attrs:{"symbol":'°C',"title":"Temperature","unit":'°Celsius',"icon":"mdi-thermometer","value":parseFloat((_vm.atmosphere.T - 273.15).toFixed(4)),"type":"number","min":-23.15,"max":71.85},on:{"input":function($event){_vm.atmosphere = Object.assign({}, _vm.atmosphere,
                      {T: parseFloat(($event + 273.15).toFixed(4))})}}})],1),_c('v-col',[_c('ValueInput',{attrs:{"symbol":'T',"title":"Temperature","unit":'Kelvin',"icon":"mdi-thermometer","value":_vm.atmosphere.T,"min":250,"max":345},on:{"input":function($event){_vm.atmosphere = Object.assign({}, _vm.atmosphere, {T: $event})}}})],1)],1),_c('ValueInput',{attrs:{"symbol":'RH',"title":'Relative humidity',"unit":'%',"icon":"mdi-water","value":_vm.atmosphere.hum_rel,"min":0.000001,"max":99.999999},on:{"input":function($event){_vm.atmosphere = Object.assign({}, _vm.atmosphere, {hum_rel: $event})}}}),_c('ValueInput',{attrs:{"symbol":'pa',"title":'Ambient atmospheric pressure',"unit":'hPa',"icon":"mdi-gauge","value":_vm.atmosphere.pa,"min":35.0,"max":1013.25},on:{"input":function($event){_vm.atmosphere = Object.assign({}, _vm.atmosphere, {pa: $event})}}})],1)],1)],1),_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-text',[(_vm.validationError)?_c('div',{staticClass:"error-hint"},[_vm._v(" "+_vm._s(_vm.validationError)+" ")]):_vm._e(),_c('p',[_vm._v(" Calculate Decay Slopes and Reverberation Time ("),_c('a',{attrs:{"href":"https://doi.org/10.1016/j.apacoust.2020.107539","target":"_blank"}},[_vm._v("Zhou et al. 2021")]),_vm._v(") ")]),_c('p',{staticClass:"text-center"},[_c('v-row',{staticClass:"text-center mt-3",attrs:{"justify":"center"}},[_c('v-btn',{staticStyle:{"margin":"5px"},attrs:{"color":"primary","x-large":"","elevation":"3","disabled":_vm.projectNotInDatabase()},on:{"click":_vm.duplicateProject},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.handleFurnishingOptionClick.apply(null, arguments)}}},[_vm._v("Save As New Project")]),_c('v-btn',{staticStyle:{"margin":"5px"},attrs:{"color":"primary","x-large":"","elevation":"3","disabled":_vm.validationError !== ''},on:{"click":_vm.saveAndSimulate}},[_vm._v("Save Project and Simulate")])],1)],1)])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }