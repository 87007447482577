










import { Component, Prop, Vue } from "vue-property-decorator";
import { formatMathHTML } from "@/helper/math-format";

@Component({
  components: {}
})
export default class ValueDisplay extends Vue {
  @Prop()
  value!: number;

  @Prop()
  unit!: string;

  @Prop()
  symbol!: string;

  @Prop()
  title!: string;

  @Prop({ default: -1 })
  decimals!: number;

  get formattedValue() {
    return this.decimals === -1
      ? this.value
      : this.value.toPrecision(this.decimals);
  }
  get unitHTML() {
    return formatMathHTML(this.unit);
  }
  get symbolHTML() {
    return formatMathHTML(this.symbol);
  }
}
