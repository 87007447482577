import { PROJECT_DATABASE } from "@/res/project";
import { store } from "@/state/state";

const checkRoomNameRules = (v: any, isIdenticalAllowed: boolean): boolean => {
  const db = PROJECT_DATABASE;
  let isSameTitle: boolean = false;
  let isCurrentProject: boolean = false;
  let isRulesAllowed: boolean = false;
  let isEmpty: boolean = false;
  const projectUUID = store.roomUUID;

  db.forEach(value => {
    if (value.title === v) {
      isSameTitle = true;
      if (value.uuid === projectUUID) {
        if (!isIdenticalAllowed) {
          isCurrentProject = true;
        }
      } else {
        isCurrentProject = true;
      }
    }
  });

  db.forEach(value => {
    if (value.title === v) isSameTitle = true;
  });

  if (v === "") isEmpty = true;

  if (isEmpty || (isCurrentProject && isSameTitle)) isRulesAllowed = false;
  else isRulesAllowed = true;

  return isRulesAllowed;
};

const roomNameRules = (isIdenticalAllowed: boolean) => [
  (v: any) => !!v || "required",
  (v: any) =>
    (v && checkRoomNameRules(v, isIdenticalAllowed)) ||
    "Unique room name required"
];

export { roomNameRules, checkRoomNameRules };
