














































































import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import ColorSwatch3D from "@/components/ColorSwatch3D.vue";
import ValueInput from "@/components/ValueInput.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import AreaAssignEditor from "@/components/AreaAssignEditor.vue";
import AssignmentPreview from "@/components/AssignmentPreview.vue";
import RoomPreview from "@/components/RoomPreview.vue";
import {
  SixRoomWalls,
  TriDimensional,
  WALL_SYMBOLS,
  WALL_TITLES
} from "@/calc/room";
import { wallsTotalArea } from "@/calc/sound-pressure-diffuse";
import { WallConfiguration } from "@/calc/area-assignment";

export interface WallEditorState {
  activeWall: 0 | 1 | 2 | 3 | 4 | 5;
  showAreaEditor: boolean;
}

@Component({
  components: {
    MaterialInput,
    ColorSwatch3D,
    ValueInput,
    AreaAssignEditor,
    RoomPreview,
    AssignmentPreview
  }
})
export default class WallEditor extends Vue {
  @VModel({ type: Array })
  private roomWalls!: SixRoomWalls;

  @Prop({ type: Object })
  state!: WallEditorState;

  @Prop({ type: Array })
  roomSize!: TriDimensional<number>;

  get wallsTotalArea() {
    return wallsTotalArea(this.roomSize);
  }

  get wallsLabel() {
    return WALL_TITLES;
  }

  get wallsSymbol() {
    return WALL_SYMBOLS;
  }

  updateWall(wIdx: number, newWall: WallConfiguration) {
    const newRoomWalls = [...this.roomWalls] as SixRoomWalls;
    newRoomWalls[wIdx] = newWall;
    this.roomWalls = newRoomWalls;
  }

  selectWallSlot(wallIndex: number) {
    if (wallIndex === this.state.activeWall) {
      return; // do nothing if already selected
    }
    const newState = {
      ...this.state,
      activeWall: wallIndex
    } as WallEditorState;
    this.$emit("stateUpdate", newState);
  }
  openAreaEditor(wallIndex: number) {
    const newState = {
      activeWall: wallIndex,
      showAreaEditor: true
    } as WallEditorState;
    this.$emit("stateUpdate", newState);
  }
  closeAreaEditor() {
    const newState: WallEditorState = {
      ...this.state,
      showAreaEditor: false
    };
    this.$emit("stateUpdate", newState);
  }
  data() {
    return {
      isActive: false, // Flag to control dropdown visibility
      dropdownOptions: [
        { value: 1, label: "Option 1", wIdx: 2 },
        { value: 2, label: "Option 2", wIdx: 3 }
        // Add more options as needed
      ]
    };
  }
}
