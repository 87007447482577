













import { Component, Prop, Vue } from "vue-property-decorator";
import {
  autoDivideRemainingArea,
  WallConfiguration
} from "@/calc/area-assignment";
import {
  ABSORPTION_DATABASE,
  Material,
  MaterialDatabase,
  SCATTERING_DATABASE
} from "@/res/materials-absorption";
import {
  materialStripePattern,
  scatteringPattern
} from "@/helper/material-pattern";

@Component({
  components: {}
})
export default class AssignmentPreview extends Vue {
  @Prop({ type: String })
  type!: "absorption" | "scattering";

  @Prop({ type: Array })
  assignments!: WallConfiguration;

  @Prop({ type: Number })
  totalArea!: number;

  get partitionSizes(): string[] {
    return autoDivideRemainingArea(this.assignments, this.totalArea).map(
      absolute => `${(absolute / this.totalArea) * 100}%`
    );
  }

  get partitionMaterials(): Material[] {
    let dB: MaterialDatabase;
    if (this.type === "scattering") {
      dB = SCATTERING_DATABASE;
      return this.assignments.map(a => dB.get(a.matSc) as Material);
    } else {
      dB = ABSORPTION_DATABASE;
      return this.assignments.map(a => dB.get(a.mat) as Material);
    }
  }

  get patternFunc(): (
    values: Material["values"]
  ) => Partial<CSSStyleDeclaration> {
    return this.type === "scattering"
      ? scatteringPattern
      : materialStripePattern;
  }

  get partitionPatterns(): Partial<CSSStyleDeclaration>[] {
    return this.partitionMaterials.map(mat => this.patternFunc(mat.values));
  }
}
