import {
  RoomSingleFreq,
  DIMENSION,
  getPerspective,
  RoomDirectionMultiFreq,
  RoomDirectionSingleFreq,
  OctoFrequential,
  DIMENSIONS,
  TriDimensional,
  OctoMappable,
  TriMappable
} from "./room";

import {
  densityOfDryAir,
  soundSpeed,
  dissipationOfAir,
  attenuationOfAir,
  Atmosphere,
  FREQUENCIES
} from "./acoustic-constants";

import {
  lateralAbsorption,
  effectiveLateralAbsorption
} from "./coefficients/absorption-lateral";

import { effectiveAbsorption } from "./coefficients/absorption-frontal";
import { effectiveScattering } from "./coefficients/scattering";
import {
  absorptionAreaVolumeRatio,
  diffuseSquaredSoundPressure,
  equivalentAbsorptionArea_Eyring,
  equivalentAbsorptionArea_Sabine,
  freePathLength3D,
  initialDiffuseSquaredSoundPressure,
  totalSurfaceArea,
  weightedAverageAlpha
} from "./sound-pressure-diffuse";
import {
  DecayFunction,
  reverberationTimeSabine,
  reverberationTimeZhou
} from "./decay-time";
import {
  initialDirectionalSquaredSoundPressure,
  directionalSquaredSoundPressure
} from "./sound-pressure-directional";
import { relativeSoundPressureLevel } from "./decibel";
export interface DimensionalResult {
  mL_i: number;
  BR_i: number;
  BR_ie: number;
  alpha_i: number;
  alpha_ie: number;
  SC_i: number;
  SC_ie: number;
  P2_i0: number;
  P2_i_t: DecayFunction;
}

export interface FrequentialResult {
  a_air: number;
  m_f: number;
  triDimensional: TriDimensional<DimensionalResult>;
  P2_s0: number;
  P2_s_t: DecayFunction;
  P2_sum0: number;
  P2_sum_t: DecayFunction;
  SPL_dB_relative: DecayFunction;
  alpha_s: number;
  A_e: number;
  A_s: number;
  AV: number;
  reverberationTimeZhou: number;
  reverberationTimeSabine: number;
}
export interface DetailedResult {
  rho_0: number;
  c_0: number;
  octoFrequential: OctoFrequential<FrequentialResult>;
  V: number;
  S: number;
  L_s: number;
}

/**
 * Summed squared sound pressure
 * p_sum^2(t) from (Zhou et al., 2021, Eq. 18)
 * @param time point of time in seconds
 * @param deltaT timestep between two datapoints
 */
export function summedSquaredSoundPressure(
  atmosphere: Atmosphere,
  room: TriDimensional<RoomDirectionMultiFreq>
  //A_o: OctoFrequential<number>
): DetailedResult {
  console.log("ROOM:", room);
  //console.log("TODO delete A_o:", A_o);
  // # Frequency-independent constants:
  const rho_0 = densityOfDryAir(atmosphere.T);
  const c_0 = soundSpeed(atmosphere.T);

  const Lx = room[DIMENSION.x].L;
  const Ly = room[DIMENSION.y].L;
  const Lz = room[DIMENSION.z].L;

  /** Volume of the room, in m³ */
  const V = Lx * Ly * Lz;

  const S = totalSurfaceArea(Lx, Ly, Lz);
  const L_s = freePathLength3D(Lx, Ly, Lz, S);
  const freqs = <OctoMappable<number>>FREQUENCIES;

  // # Frequency-dependants:
  const octoFrequential: OctoFrequential<FrequentialResult> =
    freqs.map<FrequentialResult>((f, fIdx) => {
      /** Room configuration at specified frequency */
      const room_f = room.map<RoomDirectionSingleFreq>(d => ({
        L: d.L,
        alpha: d.alpha[fIdx],
        sc: d.sc[fIdx]
      })) as RoomSingleFreq;
      const alpha_s = weightedAverageAlpha(room_f);
      const A_e = equivalentAbsorptionArea_Eyring(alpha_s, S);
      const A_s = equivalentAbsorptionArea_Sabine(alpha_s, S);

      const AV = absorptionAreaVolumeRatio(A_s, V);

      const a_air = attenuationOfAir(atmosphere, f);
      const m_f = dissipationOfAir(a_air);

      const triDims = <TriMappable<string>>DIMENSIONS;

      // # Direction-dependants:
      const triDimensional: TriDimensional<DimensionalResult> = triDims.map(
        (i, idx) => {
          const perspective = getPerspective(room_f, idx);
          const { frontal } = perspective;
          const L_i = frontal.L;

          // Lateral absorption:
          const BR_i = lateralAbsorption(perspective, c_0, f);
          const BR_ie = effectiveLateralAbsorption(BR_i, m_f, L_i);

          // Absorption:
          const alpha_i = frontal.alpha;
          const alpha_ie = effectiveAbsorption(alpha_i, m_f, L_i, BR_ie);

          // Scattering:
          const SC_i = frontal.sc;
          const SC_ie = effectiveScattering(SC_i, m_f, L_i, BR_ie, alpha_ie);

          // Initial squared sound pressure:
          const P2_i0 = initialDirectionalSquaredSoundPressure(
            L_i,
            m_f,
            BR_ie,
            alpha_ie,
            SC_ie,
            rho_0,
            c_0
          );

          /** Absorption of air for one free path length */
          const mL_i = m_f * L_i;

          // # Time-dependants:
          const P2_i_t: DecayFunction = directionalSquaredSoundPressure(
            L_i,
            c_0,
            P2_i0,
            mL_i,
            BR_ie,
            alpha_ie,
            SC_ie
          );

          const dimensional: DimensionalResult = {
            mL_i,
            BR_i,
            BR_ie,
            alpha_i,
            alpha_ie,
            SC_i,
            SC_ie,
            P2_i0,
            P2_i_t
          };
          return dimensional;
        }
      );

      // Initial diffuse squared sound pressure:
      const P2_s0 = initialDiffuseSquaredSoundPressure(
        triDimensional[0].P2_i0,
        triDimensional[1].P2_i0,
        triDimensional[2].P2_i0,
        m_f,
        rho_0,
        c_0,
        L_s,
        alpha_s,
        A_e,
        V
      );

      // Initial summed squared sound pressure:
      const P2_sum0 =
        triDimensional[0].P2_i0 +
        triDimensional[1].P2_i0 +
        triDimensional[2].P2_i0 +
        P2_s0;

      const P2_s_t: DecayFunction = diffuseSquaredSoundPressure(
        P2_s0,
        c_0,
        L_s,
        m_f,
        alpha_s
      );

      const P2_sum_t: DecayFunction = time =>
        triDimensional[0].P2_i_t(time) +
        triDimensional[1].P2_i_t(time) +
        triDimensional[2].P2_i_t(time) +
        P2_s_t(time);

      const SPL_dB_relative: DecayFunction = time =>
        relativeSoundPressureLevel(P2_sum_t(time), P2_sum0);

      const decayTimeZhou = reverberationTimeZhou(SPL_dB_relative);
      const decayTimeSabine = reverberationTimeSabine(S, V, m_f, alpha_s, c_0);
      const frequentialResult: FrequentialResult = {
        a_air,
        m_f,
        triDimensional,
        P2_s0,
        P2_s_t,
        P2_sum0,
        P2_sum_t,
        SPL_dB_relative,
        alpha_s,
        A_e,
        A_s,
        AV,
        reverberationTimeZhou: decayTimeZhou,
        reverberationTimeSabine: decayTimeSabine
      };
      return frequentialResult;
    });

  const detailedResult: DetailedResult = {
    rho_0,
    c_0,
    octoFrequential,
    L_s,
    S,
    V
  };
  return detailedResult;
}
