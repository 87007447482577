<template>
  <div>
    <LoaderScreenVue v-if="isLoading" />
    <button
      v-else
      @click="handleButtonClick"
      :class="{ 'play-button': !isAudioPlaying, 'stop-button': isAudioPlaying }"
    >
      <span v-if="!isAudioPlaying" class="play-icon"></span>
      <span v-else class="stop-icon"></span>
    </button>
  </div>
</template>

<script>
import {
  setupAudioComponent,
  stopAudio
} from "../helper/auralization/playAuralizationSound";
import LoaderScreenVue from "./LoadingAuralization.vue";

export default {
  data() {
    return {
      isLoading: false,
      isAudioPlaying: false
    };
  },
  created() {
    this.$on("audioSetupComplete", this.handleAudioSetupComplete);
    this.$on("audioStopComplete", this.handleAudioStopComplete);
    window.addEventListener("audioEnded", this.handleAudioEnded);
  },
  beforeUnmount() {
    window.removeEventListener("audioEnded", this.handleAudioEnded);
  },
  methods: {
    async setupAudio() {
      this.isLoading = true;
      await setupAudioComponent();
      this.isAudioPlaying = true;
      this.$emit("audioSetupComplete");
    },
    handleButtonClick() {
      if (this.isAudioPlaying) {
        stopAudio();
        this.$emit("audioStopComplete");
      } else {
        this.setupAudio();
      }
    },
    handleAudioSetupComplete() {
      this.isLoading = false;
    },
    handleAudioStopComplete() {
      this.isAudioPlaying = false;
    },
    handleAudioEnded() {
      this.isAudioPlaying = false;
    }
  },
  components: {
    LoaderScreenVue
  }
};
</script>
<style scoped>
.play-button {
  margin-top: 1%;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: white;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease;
}
.play-button:hover {
  background-color: #f2f2f2;
}
.play-icon {
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 20px solid #1f82c0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-35%, -50%);
}
.stop-button {
  margin-top: 1%;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: white;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease;
}
.stop-button:hover {
  background-color: #f2f2f2;
}
.stop-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #1f82c0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
